import React from "react";
import { Component } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import BlogBody from "../Shared/BlogBody";

class BlogModal extends Component {

    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <div className="modal-actions">
                        <Link target="_blank"
                            rel="noopener noreferrer" to={"/resources/" + this.props.blogObj._id}>
                            <i className="fa fa-external-link text-info cursor-pointer mr-3" aria-hidden="true"></i></Link>
                        <i className="fa fa-times text-danger cursor-pointer" aria-hidden="true" onClick={this.props.onHide}></i>
                    </div>
                    <BlogBody blogObj={this.props.blogObj} />
                </Modal.Body>
            </Modal >
        )
    }
}

export default BlogModal;