import React, { Component } from "react";
import CustomEditor from "./CustomEditor";
import { EditorState, convertFromRaw, CompositeDecorator } from "draft-js";
import moment from "moment";
import "./BlogBody.css";
import defaultImg from "../Images/vcogs1.svg";

export const linkStrategy = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "LINK"
    );
  }, callback);
};

export const Link = (props) => {
  const { contentState, entityKey } = props;
  const { url } = contentState.getEntity(entityKey).getData();
  return (
    <a
      className="link"
      href={url}
      rel="noopener noreferrer"
      target="_blank"
      aria-label={url}
    >
      {props.children}
    </a>
  );
};

const decorator = new CompositeDecorator([
  {
    strategy: linkStrategy,
    component: Link,
  },
]);
class BlogBody extends Component {
  render() {
    return (
      <div
        className={
          "blog-body" + (this.props.className ? " " + this.props.className : "")
        }
      >
        <div>
          <div className="blog-image pl-15">
            <img src={this.props.blogObj.imagePath || defaultImg} alt="blog" />
          </div>
          <h1 className="blog-title pl-15 mt-2">{this.props.blogObj.title}</h1>
          <p className="pl-15">
            {moment(this.props.blogObj.createdOn).format("MMM DD, YYYY")}
          </p>
          {this.props.blogObj.audioPath ? (
            <audio controls className="audio-tag pl-15">
              <source src={this.props.blogObj.audioPath} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          ) : null}
          <CustomEditor
            readOnly={true}
            editorState={EditorState.createWithContent(
              convertFromRaw(this.props.blogObj.blog),
              decorator
            )}
          />
        </div>
      </div>
    );
  }
}

export default BlogBody;
