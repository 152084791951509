import React from "react";
import "slick-carousel/slick/slick.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "./Slider.css";
import DevidCEO from "../../Images/David Kinney CEO.jpg";
import BradArthurMD from "../../Images/Brad Arthur Managing Director.jpg";
import JennaHoodSrSalesManager from "../../Images/Jenna Hood Sr Sales Manager.jpg";
import DylanPattersonSalesStrategist from "../../Images/Dylan Patterson Sales Strategist.jpg";
import SaraJensenAccountManager from "../../Images/Sara Jensen Account Manager.jpg";
import quote from "../../Images/quotes1.png";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        width: "50px",
        height: "50px",
        color: "white",
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "green" }}
      onClick={onClick}
    />
  );
}

export default function CustomArrows(props) {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 15000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  return (
    <div className="container-fluid bg-greenish">
      <div className="container pt-5 pb-5" data-aos="zoom-in">
        <div className="slider-book text-center">
          What They are Saying About Us
        </div>

        <Slider {...settings}>
          <div className="slider-part mt-5 mb-5 p-5">
            <div className="slider-user text-center mb-4">
              <img
                src={DevidCEO}
                className="img-fluid mx-auto user-img"
                alt="Devid CEO"
              />
            </div>
            <div className="quote text-center mb-4">
              <img src={quote} className="img-fluid mx-auto " alt="Quote" />
            </div>
            <div className="quote-deatails text-center mb-3">
              With shipment delays and increased competition on Amazon, our
              business was struggling. We were seeing a 15% year over year
              decline in sales while the industry trend was on the incline.
              Visual COGS turned our sales around. The customer service alone is
              well worth the investment.
            </div>
            <div className="quote-name text-center">David Kinney, CEO</div>
          </div>
          <div className="slider-part mt-5 mb-5 p-5">
            <div className="slider-user text-center mb-4">
              <img
                src={BradArthurMD}
                className="img-fluid mx-auto user-img"
                alt="BradArthur MD"
              />
            </div>
            <div className="quote text-center mb-4">
              <img src={quote} className="img-fluid mx-auto " alt="Quote" />
            </div>
            <div className="quote-deatails text-center mb-3">
              Between Rejected POs and our Lost Buy Box rate, we had no idea how
              much money we were leaving on the table. Visual COGS does a great
              job of highlighting what’s going well and areas that have room for
              growth. It’s a must-have for Amazon sellers.
            </div>
            <div className="quote-name text-center">
              Brad Arthur, Managing Director
            </div>
          </div>
          <div className="slider-part mt-5 mb-5 p-5">
            <div className="slider-user text-center mb-4">
              <img
                src={JennaHoodSrSalesManager}
                className="img-fluid mx-auto user-img"
                alt="JennaHood Sr Sales Manager"
              />
            </div>
            <div className="quote text-center mb-4">
              <img src={quote} className="img-fluid mx-auto " alt="Quote" />
            </div>
            <div className="quote-deatails text-center mb-3">
              When I started managing our Amazon account, I was overwhelmed.
              Keeping up with the reviews alone was difficult but developing
              content for our detail pages, I was like, where do I start? Visual
              COGS was a godsend! I couldn’t do my job without the content
              recommendations and customer satisfaction ratings.
            </div>
            <div className="quote-name text-center">
              Jenna Hood, Sr Sales Manager
            </div>
          </div>
          <div className="slider-part mt-5 mb-5 p-5">
            <div className="slider-user text-center mb-4">
              <img
                src={DylanPattersonSalesStrategist}
                className="img-fluid mx-auto user-img"
                alt="Dylan Patterson Sales Strategist"
              />
            </div>
            <div className="quote text-center mb-4">
              <img src={quote} className="img-fluid mx-auto " alt="Quote" />
            </div>
            <div className="quote-deatails text-center mb-3">
              As a vendor, using the data pinpoints found within Visual COGS has
              not only increased profits, but help me understand how to build
              out future products. It’s user-friendly interface aids perfectly
              for recognizing data I would normally spend hours gathering
              without this program.
            </div>
            <div className="quote-name text-center">
              Dylan Patterson, Sales Strategist
            </div>
          </div>
          <div className="slider-part mt-5 mb-5 p-5">
            <div className="slider-user text-center mb-4">
              <img
                src={SaraJensenAccountManager}
                className="img-fluid mx-auto user-img"
                alt="Sara Jensen Account Manager"
              />
            </div>
            <div className="quote text-center mb-4">
              <img src={quote} className="img-fluid mx-auto " alt="Quote" />
            </div>
            <div className="quote-deatails text-center mb-3">
              I use Visual COGS every single day to monitor any price decreases,
              promo offers, and/or review changes. Now my team and I can spend
              our time focusing on building out our brand and product
              development instead of wasting countless hours gathering data. All
              around product must have for your company no matter the size of
              your business.
            </div>
            <div className="quote-name text-center">
              Sara Jensen, Account Manager
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
}
