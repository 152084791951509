import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import logoImg from "../Assets/Images/Vcogs.png";
import img1 from "../Assets/Images/img11_1.png";
import img2 from "../Assets/Images/img11_2.png";
import img3 from "../Assets/Images/img11_3.png";
import tick3 from "../Images/tick3.png";

const Section10 = (props) => {
  return (
    <div className="section10-parent container-fluid bg-grn">
      <div className="section10-container container pt-5 pb-5">
        <div className="section10-layout spy-1">
          <div className="section10-body-layout flex-column">
            <div className="sec10-header" data-aos="fade-up">
              <img
                src={logoImg}
                className="logo-img"
                alt="graphics"
                data-aos="zoom-in"
              ></img>
              <h2 className="fw-bold fs-0_5 ms-5">
                {" "}
                Your New Hub for{" "}
                <span className="text-grn">Vendor Central Data</span>
              </h2>
            </div>
            <div className="sec10-grid">
              <div className="sec10-card flex-column" data-aos="zoom-in">
                <div className="empty-green-box"></div>
                <div className="sec10-card-alt">
                  <h2 className="benefit-title fw-bold fs-7">
                    Smart Management Tools
                  </h2>
                  <hr />
                  <div className="img-depict sec10-img-lg">
                    <img src={img1} className="notation" alt="graphics"></img>
                  </div>
                  <div className="key-points flex-column">
                    <div className="sec10-point">
                      <img
                        src={tick3}
                        className="green-tick"
                        alt="graphics"
                      ></img>
                      <p className="ms-3 fs-7">Comprehensive Dashboards</p>
                    </div>
                    <div className="sec10-point">
                      <img
                        src={tick3}
                        className="green-tick"
                        alt="graphics"
                      ></img>
                      <p className="ms-3 fs-7">Advanced-Data Analytics</p>
                    </div>
                    <div className="sec10-point">
                      <img
                        src={tick3}
                        className="green-tick"
                        alt="graphics"
                      ></img>
                      <p className="ms-3 fs-7">
                        Easy to Understand Tables and Graphs
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sec10-card flex-column" data-aos="zoom-in">
                <div className="empty-green-box"></div>
                <div className="sec10-card-alt">
                  <h2 className="benefit-title fw-bold fs-7">
                    Save Time and Increase Profits
                  </h2>
                  <hr />
                  <div className="img-depict sec10-img-md">
                    <img src={img2} className="notation" alt="graphics"></img>
                  </div>
                  <div className="key-points flex-column">
                    <div className="sec10-point">
                      <img
                        src={tick3}
                        className="green-tick"
                        alt="graphics"
                      ></img>
                      <p className="ms-3 fs-7">Make Lightning-Fast Decisions</p>
                    </div>
                    <div className="sec10-point">
                      <img
                        src={tick3}
                        className="green-tick"
                        alt="graphics"
                      ></img>
                      <p className="ms-3 fs-7">
                        Optimize Prices Like Never Before
                      </p>
                    </div>
                    <div className="sec10-point">
                      <img
                        src={tick3}
                        className="green-tick"
                        alt="graphics"
                      ></img>
                      <p className="ms-3 fs-7">
                        Get a Clear Overview of Competitors
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sec10-card flex-column" data-aos="zoom-in">
                <div className="empty-green-box"></div>
                <div className="sec10-card-alt">
                  <h2 className="benefit-title fw-bold fs-7">
                    Discover Hidden Opportunities
                  </h2>
                  <hr />
                  <div className="img-depict sec10-img-sm">
                    <img
                      src={img3}
                      className="notation scale-1_3"
                      alt="graphics"
                    ></img>
                  </div>
                  <div className="key-points flex-column">
                    <div className="sec10-point">
                      <img
                        src={tick3}
                        className="green-tick"
                        alt="graphics"
                      ></img>
                      <p className="ms-3 fs-7">Find Hidden Search Terms</p>
                    </div>
                    <div className="sec10-point">
                      <img
                        src={tick3}
                        className="green-tick"
                        alt="graphics"
                      ></img>
                      <p className="ms-3 fs-7">
                        Monitor the Product Pages You Care About
                      </p>
                    </div>
                    <div className="sec10-point">
                      <img
                        src={tick3}
                        className="green-tick"
                        alt="graphics"
                      ></img>
                      <p className="ms-3 fs-7">
                        Daily Updates and Alerts, Delivered to Your Inbox
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Section10)
);
