import { actions } from "../constants";

const initialState = {
    isLoggedIn: localStorage.getItem('valid-token') === 'Yes',
    userName: localStorage.getItem('name') || null,
    profilePic: localStorage.getItem('profilePicture') || null
};

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actions.LOGIN_CHANGED:
            let { isLoggedIn, userName, profilePic } = action.data;
            return {
                ...state,
                isLoggedIn,
                userName,
                profilePic
            };
        default:
            return state;
    }
};

export default reducer;