import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import emailAlert from "../Assets/Images/img13_1.png";
import notifications from "../Assets/Images/img13_2.png";
import buybox from "../Assets/Images/img13_6.png";
import inventory from "../Assets/Images/img13_3.png";
import review from "../Assets/Images/img13_7.png";
import chargeback from "../Assets/Images/img13_4.png";
import priceTracking from "../Assets/Images/img13_8.png";
import traffic from "../Assets/Images/img13_9.png";
import imgclub from "../Assets/Images/img13_5.png";

const Section7 = (props) => {
  return (
    <div className="section7-parent container-fluid bg-grn">
      <div className="section7-container container pt-5 pb-5">
        <div className="section7-layout flex-column spy-1">
          <div
            className="section7-header-portion flex-column text-center"
            data-aos="fade-up"
          >
            <h2 className="header1 mb-3 fw-bold sec7-fit-content position-relative">
              <div className="mb-30px">
                Designed by <span className="text-grn">E-commerce Experts</span>
              </div>
              <div className="abs-position-right">With Your Growth in Mind</div>
            </h2>
            <p className="sub-heading mb-3 fs-6 fw-bold d-flex flex-row-reverse justify-content-start"></p>
          </div>
          <div className="sec7-grid">
            {/* <img
              src={imgGrid}
              className="section7-img-grid"
              alt="graphics"
            ></img> */}
            <div className="el1" data-aos="fade-up">
              <div className="el1-email-alerts">
                <div>&nbsp;</div>
                <div className="p-5 bg-white label w-100 br-20px text-center pt-001">
                  <div className="emailAlert-img">
                    <img
                      data-aos="zoom-in"
                      src={emailAlert}
                      alt="graphics"
                    ></img>
                  </div>
                  Email Alerts
                </div>
              </div>
            </div>
            <div className="el2" data-aos="fade-up">
              <div className="el-arrange-items">
                <i className="fas fa-plus clr-light-green abs-right"></i>
                <div className="el-img-position">
                  <img
                    data-aos="zoom-in"
                    src={notifications}
                    alt="graphics"
                    className="el-pt"
                  ></img>
                </div>
                <div className="flex-top label mt-4">Push Notifications</div>
              </div>
            </div>
            <div className="el3" data-aos="fade-up">
              <div className="el-arrange-items">
                <i className="fas fa-plus clr-light-green abs-right"></i>
                <div className="el-img-position">
                  <img
                    src={inventory}
                    data-aos="zoom-in"
                    alt="graphics"
                    className="el-pt"
                  ></img>
                </div>
                <div className="flex-top label mt-4">Inventory Analysis</div>
              </div>
            </div>
            <div className="el4" data-aos="fade-up">
              <div className="el-arrange-items">
                <i className="fas fa-plus clr-grey abs-right"></i>
                <div className="el-img-position">
                  <img src={chargeback} data-aos="zoom-in" alt="graphics"></img>
                </div>
                <div className="flex-top label">Chargeback Reports</div>
              </div>
            </div>
            <div className="el5" data-aos="fade-up">
              <div className="el5-img-club">
                <img
                  src={imgclub}
                  data-aos="zoom-in"
                  className="img-club"
                  alt="graphics"
                ></img>
                <div className="flex-top label">Personalized Dashboard</div>
                <div className="p-4 clr-white flex-left">
                  <i className="fas fa-arrow-right white-circle"></i>
                </div>
              </div>
            </div>
            <div className="el6" data-aos="fade-up">
              <div className="el-arrange-items">
                <i className="fas fa-plus clr-light-green abs-right"></i>
                <div className="el-img-position">
                  <img
                    src={buybox}
                    data-aos="zoom-in"
                    alt="graphics"
                    className="el-pt"
                  ></img>
                </div>
                <div className="flex-top label mt-4">Buy Box Analysis</div>
              </div>
            </div>
            <div className="el7" data-aos="fade-up">
              <div className="el-arrange-items">
                <i className="fas fa-plus clr-grey abs-right"></i>
                <div className="el-img-position">
                  <img
                    src={review}
                    data-aos="zoom-in"
                    alt="graphics"
                    className="el-pt"
                  ></img>
                </div>
                <div className="flex-top label mt-4">Review Management</div>
              </div>
            </div>
            <div className="el8" data-aos="fade-up">
              <div className="el-arrange-items">
                <i className="fas fa-plus clr-light-green abs-right"></i>
                <div className="el-img-position">
                  <img
                    src={priceTracking}
                    data-aos="zoom-in"
                    alt="graphics"
                  ></img>
                </div>
                <div className="flex-top label">Price Tracking</div>
              </div>
            </div>
            <div className="el9" data-aos="fade-up">
              <div className="el-arrange-items">
                <i className="fas fa-plus clr-grey abs-right"></i>
                <div className="el-img-position">
                  <img src={traffic} data-aos="zoom-in" alt="graphics"></img>
                </div>
                <div className="flex-top label">Traffic Insights</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Section7)
);
