import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import BradArthurMD from "../Images/Brad Arthur Managing Director.jpg";

const Section1extended = (props) => {
  return (
    <div className="bg-white container-fluid sec1 pt-5 pb-5">
      <div className="container">
        <div className="last-row d-flex flex-column spy-1">
          <div className="left-img">
            <img src={BradArthurMD} className="mw-opt" alt="graphics"></img>
            <div>
              <i className="fas fa-star text-grn ms-1"></i>
              <i className="fas fa-star text-grn ms-1"></i>
              <i className="fas fa-star text-grn ms-1"></i>
              <i className="fas fa-star text-grn ms-1"></i>
              <i className="fas fa-star text-grn ms-1"></i>
            </div>
          </div>
          <div>
            <h2 className="section1-right-text">
              "Between Rejected POs and our Lost Buy Box rate, we had no idea
              how much money we were leaving on the table. Visual COGS does a
              great job of highlighting what’s going well and areas that have
              room for growth.
              <span className="fw-bold">
                {" "}
                It’s a must-have for Amazon sellers.{" "}
              </span>
              "
            </h2>
            <div className="text-grn">
              <b>Brad Arthur, Managing Director</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Section1extended)
);
