import React from "react";
import "./Subscribe.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink } from "@fortawesome/free-solid-svg-icons";
import { useHistory, withRouter } from "react-router-dom";
import { connect } from "react-redux";
const Subscribe = (props) => {
  let history = useHistory();
  return (
    <div className="subscribe-blog ">
      <div className="oval-icon p-4">
        <div className="container">
          <div className="subscribe-blog-title text-center mt-5 mb-3">
            Ready to Boost Sales and <br /> Outperform the Competition?
          </div>
          {/* <div className="subscribe-details text-center mb-5">
            Visual COGS helps you take your Amazon business to the next level by
            identifying sales opportunities, visualizing trends, and alerting
            you to issues across your catalog.
          </div> */}
          <div className="col-11 mx-auto mt-4">
            <div className="mb-5 text-center">
              {props.isLoggedIn ? (
                <button
                  className="right-btn-footer mx-3 mb-lg-0 mb-3"
                  onClick={() => {
                    window.open("https://console.visualcogs.com", "_blank");
                  }}
                >
                  Open Console
                  <span className="pl-2">
                    <FontAwesomeIcon icon={faExternalLink} />
                  </span>
                </button>
              ) : (
                [
                  <button
                    key={1}
                    className="right-btn-footer mx-3"
                    onClick={() => {
                      history.push("/request-a-demo");
                    }}
                  >
                    Request a Demo
                  </button>,
                ]
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Subscribe)
);
