import React, { useState } from "react";
import Bcarousoul from "../Home/Slider/Bcarousoul";
import FCarosoul from "../Home/Slider/FCarosoul";
import Navbar from "../Shared/Navbar/Navbar";
import Shared from "../Shared/Shared";
import mail from "../Images/envelope.svg";
import "./Blog.css";
import { subscribe } from "../../constants";
import { Helmet } from "react-helmet";
import Footer from "../Landing/Footer";

const Blog = () => {
  let [email, setEmail] = useState("");

  return (
    <div className="blog-page">
      <Helmet>
        <title>Resources | Learn From E-commerce Experts</title>
        <meta
          name="description"
          content="Explore the best strategies and techniques to stay ahead of your rivals in the competitive marketplace with our free offerings. Subscribe to read more and stay updated."
        />
      </Helmet>
      <Navbar />
      <Shared />
      <div className="header-blog">
        <div className="container mt-5 mb-5">
          <h1 className="blog-page-title text-center mb-5">
            Learn from the <span>Best</span> to Stay Ahead in the <br />{" "}
            <span>Marketplace</span> with the <span>Latest Tips</span> and{" "}
            <span>Strategies</span>
          </h1>
          <div className="col-lg-6 col-11 mx-auto">
            <div className="input-btn-width shadow p-2 d-flex mb-4 w-100 d-none">
              <img src={mail} alt="Mail" className="ms-2 img-fluid" />
              <input
                type="text"
                className="w-100 input-subscribe"
                placeholder="Enter Your Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button
                className="button wd-450-none"
                disabled={
                  !email ||
                  !email.trim ||
                  // eslint-disable-next-line
                  !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
                }
                onClick={() => {
                  subscribe(email, () => {
                    setEmail("");
                  });
                }}
              >
                Subscribe
              </button>
            </div>
            <button
              className="subscribe shadow w-100 wd-450-block d-none"
              disabled={
                !email ||
                !email.trim ||
                // eslint-disable-next-line
                !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
              }
              onClick={() => {
                subscribe(email, () => {
                  setEmail("");
                });
              }}
            >
              Subscribe
            </button>
          </div>
        </div>
      </div>
      <Bcarousoul />
      <FCarosoul />
      <Footer />
    </div>
  );
};

export default Blog;
