import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import dataClarityImg from "../Assets/Images/img6_1.png";

const Section4 = (props) => {
  return (
    <div className="section4-parent container-fluid bg-grn">
      <div className="section4-container container pt-5 pb-5">
        <div className="section4-body sec4-grid spy-1">
          <div className="left-img-part">
            <img
              data-aos="zoom-in"
              src={dataClarityImg}
              className="section4-main-img"
              alt="graphics"
            ></img>
          </div>
          <div className="right-content-part flex-column" data-aos="fade-up">
            <h2 className="content-part-heading fw-bold mb-4">
              Data Clarity <span className="text-grn">Unlocked in Minutes</span>
              , Not Hours
            </h2>
            <p className="content-detail fs-4 mb-3">
              <span className="fw-bold">
                "A game-changing solution that's made our previous
                data-gathering struggles a thing of the past.
              </span>{" "}
              Visual COGS has{" "}
              <span className="text-decoration-underline">
                transformed our profitability
              </span>{" "}
              and product development with its intuitive interface for data
              analysis."
            </p>
            <p className="text-grn fs-4 fw-bold">
              Dylan Patterson, Sales Strategist
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Section4)
);
