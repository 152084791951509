import React, { useState } from "react";
import { NavLink, useHistory, withRouter } from "react-router-dom";
import logo from "../../Images/vcogs2.png";
import "./Navbar.css";
import { connect } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink } from "@fortawesome/free-solid-svg-icons";

const Navbar = (props) => {
  console.log(props);
  let history = useHistory();
  const [color, setColor] = useState(false);
  const changeColor = () => {
    if (window.scrollY >= 110) {
      setColor(true);
    } else {
      setColor(false);
    }
  };
  window.addEventListener("scroll", changeColor);

  return props?.location?.pathname === "/request-a-demo" ? null : (
    <nav
      className={
        color
          ? "navbar navbar-expand-lg sticky-top scroll box-shadow"
          : "navbar navbar-expand-lg sticky-top withoutScroll"
      }
    >
      <div className="container">
        <NavLink to="/" className="navbar-brand d-lg-none d-inline-block">
          <img
            src={logo}
            width="130px"
            className="img-fluid"
            alt="Visual COGS Logo"
          />
        </NavLink>
        <button
          className="navbar-toggler collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="toggler-icon top-bar"></span>
          <span className="toggler-icon middle-bar"></span>
          <span className="toggler-icon bottom-bar"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav  ms-auto text-lg-center mb-lg-0">
            <li className="nav-item mx-2 p-2 d-lg-none d-inline-block mb-lg-0 mb-3">
              <NavLink to="/plans" className="nav-link a-link">
                Pricing
              </NavLink>
            </li>
            <li className="nav-item mx-2 p-2 d-lg-none d-inline-block mb-lg-0 mb-3">
              <NavLink to="/resources" className="nav-link a-link">
                Resources
              </NavLink>
            </li>
            <li className="nav-item mx-2 p-2 d-lg-none d-inline-block mb-lg-0 mb-3">
              <NavLink to="/about-us" className="nav-link a-link">
                Our Story
              </NavLink>
            </li>
            {/* <li className="nav-item mx-2 p-2 d-lg-none d-inline-block mb-lg-0 mb-3">
              <NavLink to="/we-are-hiring" className="nav-link a-link">
                We‘re Hiring
              </NavLink>
            </li> */}
            <li className="nav-item mx-2 p-2 d-lg-none d-inline-block mb-lg-0 mb-3">
              <NavLink to="/faqs" className="nav-link a-link">
                FAQs
              </NavLink>
            </li>

            {!props.isLoggedIn
              ? [
                  <li
                    key={0}
                    className="nav-item mx-2 p-2 d-lg-none d-inline-block mb-lg-0 mb-3"
                  >
                    <a
                      className="nav-link a-link"
                      href="https://console.visualcogs.com/signup"
                    >
                      Sign up
                    </a>
                  </li>,
                  <li
                    key={1}
                    className="nav-item mx-2 p-2 d-lg-none d-inline-block mb-lg-0 mb-3"
                  >
                    <a
                      className="nav-link a-link"
                      href="https://console.visualcogs.com/signin"
                    >
                      Login
                    </a>
                  </li>,
                ]
              : null}

            {props.isLoggedIn ? (
              <button
                type="button"
                className="button ms-lg-5"
                onClick={() => {
                  window.open("https://console.visualcogs.com", "_blank");
                }}
              >
                Open Console{" "}
                <span className="pl-2">
                  <FontAwesomeIcon icon={faExternalLink} />
                </span>
              </button>
            ) : (
              <button
                type="button"
                className="button ms-lg-5"
                onClick={() => {
                  history.push("/request-a-demo");
                }}
              >
                Request a Demo
              </button>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.isLoggedIn,
    userName: state.userName,
    profilePic: state.profilePic,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar));
