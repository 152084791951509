import React, { Component } from "react";
import "slick-carousel/slick/slick.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import bolg from "../../Images/vcogs1.svg";
import user from "../../Images/fbk-user1.png";
import "./carosoul.css";
import { BackendAPIs, getBaseApi, postCall } from "../../../constants";
import moment from "moment";
import BlogModal from "../../BlogComponent/BlogModal";
import { withRouter } from "react-router-dom";

const limit = (() => {
  let w = window.innerWidth;
  if (w <= 480) {
    return 1;
  }
  if (w <= 600) {
    return 2;
  }
  if (w <= 1024) {
    return 3;
  }
  return 4;
})();
class Featurepost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: -1,
      blogs: [],
      left: 0,
      right: 0,
      show: false,
      currentBlog: {},
    };

    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }

  componentDidMount() {
    this.getBlogs(0, limit);
  }

  getBlogs(offset, limit) {
    postCall(getBaseApi() + BackendAPIs.generic.blogs.featuredBlogs, {}).then(
      (resp) => {
        this.setState({
          count: resp.count,
          blogs: resp.blogs,
          left: offset,
          right: offset + limit,
        });
      }
    );
  }

  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  onHide() {
    this.setState({ show: false });
  }
  render() {
    var settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 0,
      beforeChange: (current, next) => {
        if (next < 0) {
          setTimeout(() => {
            this.slider.slickGoTo(0);
          }, 1000);
          return;
        }
        this.setState({
          left: next,
          right: next + limit,
        });
      },
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="blog-feataure p-3 p-lg-5">
        <div className="container mt-5">
          <div className="title-blog d-lg-flex d-block text-center justify-content-between mb-3">
            <h3
              data-aos="fade-up"
              className="text-lg-start text-center feature-slider"
            >
              Featured Post
            </h3>
            <div className="d-none d-lg-block">
              <button
                className="button-pev shadow me-3"
                disabled={this.state.left === 0}
                onClick={this.previous}
              >
                <i className="fas fa-angle-left"></i>
              </button>
              <button
                className="button-next shadow"
                disabled={this.state.right >= this.state.count}
                onClick={this.next}
              >
                <i className="fas fa-angle-right"></i>
              </button>
            </div>
          </div>
          <Slider
            ref={(c) => (this.slider = c)}
            {...settings}
            data-aos="zoom-in"
          >
            {this.state.blogs.map((d, i) => {
              let blog = d || {};
              return (
                <div
                  key={i}
                  className="grid-box-slider carousel-item active p-2 cursor-pointer"
                  onClick={() => {
                    window.open(
                      "/resources/" +
                        blog._id +
                        "/" +
                        encodeURIComponent(blog.urlTitle || blog.title || ""),
                      "_blank"
                    );
                  }}
                >
                  <div className="top-part mb-4 d-flex justify-content-between  align-items-center">
                    <div className="blog-img">
                      <img
                        src={blog.smallImagePath || blog.imagePath || bolg}
                        alt="Blog"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div className="bottom-title mb-3">{blog.title}</div>
                  <div className="blog-owner align-items-center d-flex mb-3">
                    <div className="user me-3">
                      <img
                        src={blog.bloggerPic || user}
                        alt="Blog Author"
                        className="img-fluid blogger-img"
                      />
                    </div>
                    <div className="extra-text d-flex justify-content-between align-items-center">
                      <span className="">{blog.bloggerName || "Admin"}</span>
                      <span className="start mx-3">|</span>
                      <span>
                        {blog.createdOn
                          ? moment(blog.createdOn).format("MMM DD, YYYY")
                          : "Nov 5, 2018"}
                      </span>
                    </div>
                  </div>
                  <div className="bottom-deatils mb-3">
                    {blog.shortDesc
                      ? blog.shortDesc.substring(0, 100) + "..."
                      : `There are many variations of passages of Lorem Ipsum available, but the majority have`}{" "}
                    <button className="btn btn-link inline-block btn-sm read-more-btn">
                      Read More
                    </button>
                  </div>
                </div>
              );
            })}
          </Slider>
          <div className="text-center mt-3 mb-3 d-lg-none">
            <button
              className="button-pev shadow me-3"
              disabled={this.state.left === 0}
              onClick={this.previous}
            >
              <i className="fas fa-angle-left"></i>
            </button>
            <button
              className="button-next shadow"
              disabled={this.state.right >= this.state.count}
              onClick={this.next}
            >
              <i className="fas fa-angle-right"></i>
            </button>
          </div>
        </div>
        <BlogModal
          show={this.state.show}
          onHide={this.onHide.bind(this)}
          blogObj={this.state.currentBlog}
        />
      </div>
    );
  }
}

export default withRouter(Featurepost);
