import React, { Component, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import dailyDataCollection from "../Assets/Images/img3_1.png";
import integration from "../Assets/Images/img3_2.png";
import analysis from "../Assets/Images/img3_3.png";
import visualization from "../Assets/Images/img3_4.png";
import insights from "../Assets/Images/img3_5.png";
import smartDecisionMaking from "../Assets/Images/img3_6.png";
import thumbnailImg from "../Images/Thumbnail1.png";
import { Modal } from "react-bootstrap";

const Section2 = (props) => {
  let [modalflag, setModalFlag] = useState(false);

  let showModal = () => {
    setModalFlag(true);
  };

  let hideModal = () => {
    setModalFlag(false);
  };
  return (
    <div className="section2-parent container-fluid bg-grn">
      <div className="section2-container container pt-5 pb-5">
        <div className="spy-1">
          <div className="top-row sec2-grid">
            <div className="sec2-1st cursor-pointer" onClick={showModal}>
              <img data-aos="zoom-in" src={thumbnailImg} alt="graphics"></img>
            </div>
            <div className="sec2-2nd" data-aos="fade-up">
              <h2 className="fs-0_5 fw-bold text-wrap">
                Break Free from Spreadsheet Overload:
              </h2>
              <p className="fs-2 text-wrap">
                Gain Instant Clarity to Drive Tangible Business Growth
              </p>
              <button
                className="button w-100 bolder-font text-start explore-btn"
                type="button"
                onClick={() => {
                  window.open("https://www.visualcogs.com/request-a-demo");
                }}
              >
                Request a Demo
              </button>
            </div>
          </div>
          <br />
          <div className="bottom-row sec2-grid2">
            <div data-aos="fade-up" className="feature">
              <img
                src={dailyDataCollection}
                className="feature-img"
                alt="graphics"
              ></img>{" "}
              <p className="feature-name text-center">Daily Data Collection</p>{" "}
            </div>
            <div data-aos="fade-up" className="feature">
              <img
                src={integration}
                className="feature-img"
                alt="graphics"
              ></img>{" "}
              <p className="feature-name text-center">Integration</p>{" "}
            </div>

            <div data-aos="fade-up" className="feature">
              <img src={analysis} className="feature-img" alt="graphics"></img>{" "}
              <p className="feature-name text-center">Analysis</p>{" "}
            </div>

            <div data-aos="fade-up" className="feature">
              <img
                src={visualization}
                className="feature-img"
                alt="graphics"
              ></img>{" "}
              <p className="feature-name text-center">Visualization</p>{" "}
            </div>

            <div data-aos="fade-up" className="feature">
              <img src={insights} className="feature-img" alt="graphics"></img>{" "}
              <p className="feature-name text-center">Insights</p>{" "}
            </div>

            <div data-aos="fade-up" className="feature">
              <img
                src={smartDecisionMaking}
                className="feature-img"
                alt="graphics"
              ></img>{" "}
              <p className="feature-name text-center">Smart Decision Making</p>{" "}
            </div>
          </div>
        </div>
      </div>
      <SlideShowModal show={modalflag} onHide={hideModal} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Section2)
);

class SlideShowModal extends Component {
  componentDidUpdate(oldProps) {
    if (this.props.show && this.props.show !== oldProps.show) {
      let el = document.getElementsByTagName("video");
      if (el && el[0]) {
        el[0].play();
      }
    }
  }

  render() {
    return (
      <Modal
        className={"image-slider singleImage"}
        show={this.props.show}
        onHide={this.props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="carousel-modal">
          <iframe
            title="Visual COGS in 1 minute 20 seconds"
            width="100%"
            height="480"
            src="https://www.youtube.com/embed/_eXOIhNPZbY"
            frameborder="0"
            allowfullscreen
          ></iframe>
        </Modal.Body>
      </Modal>
    );
  }
}
