import React from "react";
import CustomArrows from "../Home/Slider/Slider";
import Navbar from "../Shared/Navbar/Navbar";
import Shared from "../Shared/Shared";
import Package from "./Package";
import "../Home/Pricing/Pricing.css";
import Subscribe from "./Subscribe";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import Footer from "../Landing/Footer";
import { useHistory } from "react-router-dom";

const PricingPage = ({ isLoggedIn }) => {
  let history = useHistory();

  return (
    <div className="pricing">
      <Helmet>
        <title>Plans | Select The Best Plan</title>
        <meta
          name="description"
          content="Visual COGS provides three pricing tiers for Amazon and Costco Vendors to help choose a plan that fits you. Request a Demo Today!"
        />
      </Helmet>
      <Navbar />
      <Shared />
      <div className="container">
        <div className="col-11 mx-auto">
          <h1 className="pricing-header-title mt-5 mb-2 text-center">
            Choose a <span> Plan</span> That’s Right <br /> for You.
          </h1>
          {/* <div className="d-flex justify-content-center mb-3">
						{checked ? (
							<div className="monthly me-3">Monthly</div>
						) : (
							<div className="monthly-color me-3">Monthly</div>
						)}

						<div className="form-check form-switch">
							<input
								className="form-check-input"
								type="checkbox"
								checked={checked}
								onChange={handleChange}
								id="flexSwitchCheckChecked"
							/>
						</div>
						{checked ? (
							<div className="monthly-color ms-3">Annually</div>
						) : (
							<div className="monthly ms-3">Annually</div>
						)}
					</div> */}
        </div>
      </div>

      {isLoggedIn ? null : (
        <div className="mt-5 jumbotron flex-around">
          <div className="h1">
            Try <span className="color">Visual COGS</span> free for{" "}
            <span className="color">14</span> days
          </div>
          <button
            className="btn-withselect w-100 d-flex justify-content-between align-items-center"
            onClick={() => {
              history.push("/request-a-demo");
            }}
          >
            <span>Request a Demo Today</span>
            <i className="fas fa-arrow-right ml-3"></i>
          </button>
        </div>
      )}

      <Package type="Amazon" />

      {/* <Package type="Costco" /> */}

      <div className="nav-position" id="testimonials"></div>
      <CustomArrows />
      <Subscribe />
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PricingPage);
