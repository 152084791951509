import React from "react";
import { Helmet } from "react-helmet";

import "./Landing.css";
import Navbar from "../Shared/Navbar/Navbar";
import { connect } from "react-redux";
import TopNav from "./TopNav";
import Hero from "./Hero";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Section5 from "./Section5";
import Section6 from "./Section6";
import Section7 from "./Section7";
import Section9 from "./Section9";
import Section10 from "./Section10";
import Section11 from "./Section11";
import Section13 from "./Section13";
import Footer from "./Footer";
import Section1extended from "./Section1extended";
import { useState } from "react";
import { BackendAPIs, getBaseApi, postCall } from "../../constants";
import { toast } from "react-toastify";

const Landing = (props) => {
  let [email, setEmail] = useState("");
  return (
    <div className="landing">
      <Helmet>
        <title>Visual COGS | Homepage</title>
        <meta
          name="description"
          content="An e-commerce analytics tool that provides comprehensive insights of your product listings on several E-commerce platforms like Costco and Amazon."
        />
      </Helmet>
      <Navbar />
      <div className="container-fluid p-0 minheight-hero">
        <div className="container">
          <TopNav />
          <Hero />
        </div>
      </div>
      <div className="jumbotron flex-around m-0">
        <div class="input-group mb-1 max-width-600px">
          <input
            type="email"
            class="form-control"
            placeholder="Enter your email"
            aria-label="Enter your email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            value={email}
          />
          <div>
            <div class="input-group-append bg-black">
              <button
                disabled={
                  !email ||
                  // eslint-disable-next-line
                  !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
                }
                class="input-group-text btn btn-dark btn-clr"
                id="basic-addon"
                onClick={() => {
                  postCall(
                    getBaseApi() + BackendAPIs.generic.joinWaitlist,
                    {
                      email,
                    },
                    false,
                    null,
                    true
                  ).then(
                    (resp) => {
                      setEmail("");
                      toast(
                        "You have successfully joined Visual COGS waitlist."
                      );
                      this.props.onHide && this.props.onHide();
                    },
                    (err) => {
                      let message = "Something went wrong";
                      if (err.responseJSON && err.responseJSON.message) {
                        message = err.responseJSON.message;
                      }
                      toast(`Error: [${message}].`);
                    }
                  );
                }}
              >
                Join Waitlist
              </button>
            </div>
          </div>
        </div>
      </div>
      <Section1 />
      <Section1extended />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Section7 />
      <Section9 />
      <Section10 />
      <Section11 />
      <Section13 />
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Landing);
