import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import logo from "../Images/vcogs2.png";
import { connect } from "react-redux";

const Shared = ({ isLoggedIn, userName, profilePic }) => {
  return (
    <nav className="navbar navbar-expand-lg box-shadow d-none d-lg-block">
      <div className="container mb-5">
        <NavLink className="navbar-brand" to="/">
          <img
            src={logo}
            width="130px"
            alt="Visual COGS Logo"
            className="logo img-fluid"
          />
        </NavLink>
        <button
          className="navbar-toggler collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="toggler-icon top-bar"></span>
          <span className="toggler-icon middle-bar"></span>
          <span className="toggler-icon bottom-bar"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item mx-2 mb-lg-0 mb-2">
              <NavLink className="nav-link a-link" to="/plans">
                Plans
              </NavLink>
            </li>
            <li className="nav-item mx-2 mb-lg-0 mb-2">
              <NavLink className="nav-link a-link" to="/resources">
                Resources
              </NavLink>
            </li>
            <li className="nav-item mx-2 mb-lg-0 mb-2">
              <NavLink className="nav-link a-link" to="/about-us">
                Our Story
              </NavLink>
            </li>
            {/* <li className="nav-item mx-2 mb-lg-0 mb-2">
              <NavLink className="nav-link a-link" to="/we-are-hiring">
                We‘re Hiring
              </NavLink>
            </li> */}
            <li className="nav-item mx-2 mb-lg-0 mb-2">
              <NavLink className="nav-link a-link" to="/faqs">
                FAQs
              </NavLink>
            </li>

            <div className="d-flex align-items-center">
              {!isLoggedIn ? (
                [
                  <li key={0} className="nav-item mx-3 mb-lg-0 mb-2">
                    <a
                      className="nav-link"
                      href="https://console.visualcogs.com/signup"
                    >
                      Sign up
                    </a>
                  </li>,
                  <li key={1} className="nav-item mx-3 mb-lg-0 mb-2">
                    <a
                      className="nav-link"
                      href="https://console.visualcogs.com/signin"
                    >
                      Login
                    </a>
                  </li>,
                ]
              ) : (
                <li key={1} className="nav-item mx-3 mb-lg-0 mb-2">
                  <span className="greet">
                    Hi <span className="color-green text-bold">{userName}</span>
                  </span>
                  {profilePic ? (
                    <img
                      className="blogger-img ml-3"
                      src={profilePic}
                      alt="profile"
                    ></img>
                  ) : null}
                  {/* <a className="nav-link inline-block" href="https://console.visualcogs.com/signin"
									target='_blank' rel="noreferrer">
										Open Console <span className="color-green pl-2"><FontAwesomeIcon icon={faExternalLink} /></span>
									</a> */}
                </li>
              )}
              {/* <li className="nav-item mx-3 mb-lg-0 mb-2">
								<i className="fas fa-search"></i>
							</li> */}
            </div>
          </ul>
        </div>
      </div>
    </nav>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.isLoggedIn,
    userName: state.userName,
    profilePic: state.profilePic,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Shared));
