import React from "react";
import Navbar from "../Shared/Navbar/Navbar";
import Shared from "../Shared/Shared";
import line from "../Images/about1.svg";
import right from "../Images/about1.png";
import left from "../Images/about2.png";
import "./About.css";
import Principle from "./Principle/Principle";
import Latest from "./Latest/Latest";
import { Helmet } from "react-helmet";
import Footer from "../Landing/Footer";

const About = () => {
  return (
    <div className="about-page">
      <Helmet>
        <title>Our Story | About Visual COGS</title>
        <meta
          name="description"
          content="Learn more about Visual COGS, our global team, our mission, and how we visualized to empower Amazon and Costco Vendors with the data they need to maximize their growth."
        />
      </Helmet>
      <Navbar />
      <Shared />
      <div className="about-us-header " data-aos="fade-up">
        <h1 className="about-us-title text-center mb-3">Who Are We?</h1>
        <div className="about-us-deatils mb-5 p-3 text-center">
          We are a team of e-commerce specialists dedicated to empowering
          businesses <br className="d-none d-lg-block" />
          with the information they need to grow sales online.
        </div>
      </div>
      <div className="our-story-bg mb-5 mt-5 p-4" id="story">
        <div className="container mt-5 mb-5">
          {/* left part  */}
          <div className="ourstory mb-3 d-lg-none align-items-center">
            <div className="ourstory-title text-lg-start text-center ">
              Our story
            </div>
            <div className="text-center">
              <img src={line} className="text-center" alt="Line" />
            </div>
          </div>
          <div className="row align-items-center">
            <div
              className="col-lg-4 col-11 text-lg-start text-center mx-auto "
              data-aos="fade-up"
            >
              <div className="ourstory d-none d-lg-flex mb-3  align-items-center">
                <div className="ourstory-title">Our Story</div>
              </div>
              <div className="ourstory-deatils mb-3">
                Founded in 2020, Visual COGS was created by ex-Amazonians,
                skilled developers, and e-commerce experts. Understanding the
                pain it takes to stay ahead of cut-throat competition, the
                founders came together to create easy-to-use dashboards filled
                with advanced analytics and insider insights to help growing
                businesses successfully navigate the complex world of
                e-commerce.
              </div>
              {/* <button className="learn-more mt-lg-0 mt-3 mb-5 text-lg-start text-center">
								Learn More
							</button> */}
            </div>
            {/* right-part  */}
            <div
              className="col-lg-6 order-first order-lg-last  col-11 mt-lg-0 mt-3 mx-auto"
              data-aos="fade-down"
            >
              <img src={right} alt="Right" className="img-fluid mb-5" />
            </div>
          </div>
          <div className="row align-items-center">
            {/* left  */}
            <div className="ourstory mb-3 d-lg-none align-items-center">
              <div className="ourstory-title text-lg-start text-center ">
                Our Mission
              </div>
              <div className="text-center">
                <img src={line} className="text-center" alt="Line" />
              </div>
            </div>
            <div
              className="col-lg-6 col-11 text-lg-start text-center mx-auto"
              data-aos="fade-up"
            >
              <img src={left} alt="Left" className="img-fluid mt-5 mb-5" />
            </div>
            {/* right part  */}
            <div
              className="col-lg-4 text-lg-start text-center col-11 mx-auto"
              data-aos="fade-down"
            >
              <div className="ourstory d-none mb-3 d-lg-flex align-items-center">
                <div className="ourstory-title text-lg-start text-center ">
                  Our Mission
                </div>
              </div>
              <div className="ourstory-deatils mb-3">
                Help vendors and sellers maximize their potential and boost
                sales through easy-to-use dashboards and advanced insights
              </div>
            </div>
          </div>
        </div>
      </div>
      <Principle />
      {/* <Leadership /> */}
      <Latest />
      <Footer />
    </div>
  );
};

export default About;
