import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import photo1 from "../Images/David Kinney CEO.jpg";
import photo2 from "../Images/Sara Jensen Account Manager.jpg";
import photo3 from "../Images/Jenna Hood Sr Sales Manager.jpg";

const Section9 = (props) => {
  return (
    <div className="section9-parent container-fluid">
      <div className="section9-container container pt-5 pb-5">
        <div className="section9-layout spy-1">
          <div className="section9-body-layout flex-column text-center">
            <div className="section-header fw-bold fs-1" data-aos="fade-up">
              <h2 className="fs-0 mb-3 fw-bold">
                Hear Directly From Our <span className="text-grn">Clients</span>
              </h2>
            </div>
            <div className="sec-9-grid">
              <div className="mt-3" data-aos="fade-up">
                <div className="client-photo">
                  <img
                    data-aos="zoom-in"
                    src={photo1}
                    className="photo-img rounded-circle"
                    alt="graphics"
                  ></img>
                </div>
                <div className="sec9-card">
                  <div className="client-name fw-bold fs-7 text-grn">
                    David Kinney
                  </div>
                  <div className="client-rating">
                    {" "}
                    <i className="fas fa-star text-grn ms-1"></i>
                    <i className="fas fa-star text-grn ms-1"></i>
                    <i className="fas fa-star text-grn ms-1"></i>
                    <i className="fas fa-star text-grn ms-1"></i>
                    <i className="fas fa-star text-grn ms-1"></i>
                  </div>
                  <div className="client-opinion text-justify fs-5">
                    “With shipment delays and increased competition on Amazon,
                    our business was struggling. We were seeing a 15% year over
                    year decline in sales while the industry trend was on the
                    incline. Visual COGS turned our sales around. The customer
                    service alone is well worth the investment.”
                  </div>
                </div>
              </div>

              <div data-aos="fade-up">
                <div className="client-photo-scale">
                  <img
                    data-aos="zoom-in"
                    src={photo2}
                    className="photo-img rounded-circle"
                    alt="graphics"
                  ></img>
                </div>
                <div className="sec9-card bg-grn-alt scale1_1 pt-50px">
                  <div className="client-name fw-bold fs-7 text-grn">
                    Sara Jensen
                  </div>
                  <div className="client-rating">
                    {" "}
                    <i className="fas fa-star text-grn ms-1"></i>
                    <i className="fas fa-star text-grn ms-1"></i>
                    <i className="fas fa-star text-grn ms-1"></i>
                    <i className="fas fa-star text-grn ms-1"></i>
                    <i className="fas fa-star text-grn ms-1"></i>
                  </div>
                  <div className="client-opinion text-justify fs-5">
                    “I use Visual COGS every single day to monitor any price
                    decreases, promo offers, and/or review changes. Now my team
                    and I can spend our time focusing on building out our brand
                    and product development instead of wasting countless hours
                    gathering data. All around product MUST have for your
                    company no matter the size of your business.”
                  </div>
                </div>
              </div>

              <div className="mt-3" data-aos="fade-up">
                <div className="client-photo">
                  <img
                    data-aos="zoom-in"
                    src={photo3}
                    className="photo-img rounded-circle"
                    alt="graphics"
                  ></img>
                </div>
                <div className="sec9-card">
                  <div className="client-name fw-bold fs-7 text-grn">
                    Jenna Hood
                  </div>
                  <div className="client-rating">
                    {" "}
                    <i className="fas fa-star text-grn ms-1"></i>
                    <i className="fas fa-star text-grn ms-1"></i>
                    <i className="fas fa-star text-grn ms-1"></i>
                    <i className="fas fa-star text-grn ms-1"></i>
                    <i className="fas fa-star text-grn ms-1"></i>
                  </div>
                  <div className="client-opinion text-justify fs-5">
                    “When I started managing our Amazon account, I was
                    overwhelmed. Keeping up with the reviews alone was difficult
                    but developing content for our detail pages, I was like,
                    where do i start? Visual COGS was a godsend! I couldn’t do
                    my job without the content recommendations and customer
                    satisfaction ratings.”
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="sec6-flex-end spy-1">
          <button
            className="button explore-btn"
            type="button"
            onClick={() => {
              window.open("https://www.visualcogs.com/request-a-demo");
            }}
          >
            Request a Demo
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Section9)
);
