import React, { Component } from "react";
import Navbar from "../Shared/Navbar/Navbar";
import tick from "../Images/tick3.png";
import "./Home.css";
import Landing from "../Shared/Navbar/Landing";
import { BackendAPIs, getBaseApi, postCall } from "../../constants";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import * as qs from "query-string";
import { ReactComponent as Twitter } from "../Images/x-twitter.svg";

class Home extends Component {
  constructor(props) {
    super(props);
    let { referral } = qs.parse(props.location.search);
    if (referral) {
      localStorage.setItem("referral", referral);
    }

    this.state = {
      name: "",
      email: "",
      businessName: "",
      phoneNumber: "",
      validName: false,
      validEmail: false,
      validBname: false,
      validPhone: false,
    };
  }

  scheduleDemo(e) {
    e.preventDefault();
    postCall(
      getBaseApi() + BackendAPIs.scheduleDemo,
      {
        name: this.state.name,
        email: this.state.email,
        businessName: this.state.businessName,
        phoneNumber: this.state.phoneNumber,
        referalCode: localStorage.getItem("referral"),
      },
      false,
      null,
      true
    ).then(
      (resp) => {
        this.setState({
          name: "",
          email: "",
          businessName: "",
          phoneNumber: "",
          validName: false,
          validEmail: false,
          validBname: false,
          validPhone: false,
        });
        toast(
          "Your request has been accepted. Someone from our team will contact you soon."
        );
        this.props.history.push("/request-a-demo?success=Yes");
      },
      (err) => {
        let message = "Something went wrong";
        if (err.responseJSON && err.responseJSON.message) {
          message = err.responseJSON.message;
        }
        toast(`Error: [${message}].`);
      }
    );
  }

  render() {
    return (
      <div className="header">
        <Helmet>
          <title>Visual COGS | Request a Demo</title>
          <meta
            name="description"
            content="Among about seven million sellers, only 1.9 million sellers are actively selling on the marketplace."
          />
        </Helmet>
        <Navbar />
        <Landing />
        <div className="container home-center">
          <div className="row mb-3">
            {/* left part from box */}
            <div className="col-11  col-lg-4 mx-auto">
              <div className="from-box mb-2 p-4">
                <div className="from-text mb-2">Request A Demo!</div>
                <form>
                  <div className="mb-3">
                    <input
                      type="text"
                      className="form-control home-input"
                      placeholder="Your Name"
                      id="name"
                      aria-describedby="emailHelp"
                      value={this.state.name}
                      onChange={(e) => {
                        let val = e.target.value;
                        let isValid = false;
                        if (val.trim()) {
                          isValid = true;
                        }
                        this.setState({
                          name: val,
                          validName: isValid,
                        });
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      type="text"
                      placeholder="Business Name"
                      className="form-control home-input"
                      id="bname"
                      value={this.state.businessName}
                      onChange={(e) => {
                        let val = e.target.value;
                        let isValid = false;
                        if (val.trim()) {
                          isValid = true;
                        }
                        this.setState({
                          businessName: val,
                          validBname: isValid,
                        });
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      type="email"
                      placeholder="Email Address"
                      className="form-control home-input"
                      id="email"
                      value={this.state.email}
                      onChange={(e) => {
                        let val = e.target.value;
                        let isValid = false;
                        if (
                          val.trim() &&
                          // eslint-disable-next-line
                          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                            val
                          )
                        ) {
                          isValid = true;
                        }
                        this.setState({
                          email: val,
                          validEmail: isValid,
                        });
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      type="text"
                      placeholder="Phone No."
                      className="form-control home-input"
                      id="phone"
                      value={this.state.phoneNumber}
                      onChange={(e) => {
                        let val = e.target.value;
                        let isValid = false;
                        if (
                          val.trim() &&
                          // eslint-disable-next-line
                          /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
                            val
                          )
                        ) {
                          isValid = true;
                        }
                        this.setState({
                          phoneNumber: val,
                          validPhone: isValid,
                        });
                      }}
                    />
                  </div>
                  <button
                    disabled={
                      !this.state.validName ||
                      !this.state.validBname ||
                      !this.state.validEmail ||
                      !this.state.validPhone
                    }
                    type="submit"
                    className="btn button w-100 btn-primary mb-3 p-3"
                    onClick={this.scheduleDemo.bind(this)}
                  >
                    Request Free Demo
                  </button>
                </form>
              </div>

              <div className="new-data w-100 mb-2">
                <div className="line"></div>
                <span className="new-data-text text-center">
                  Reach out instead
                </span>
                <div className="line"></div>
              </div>
              {/* icon part  */}
              <div className="icon-part-home mb-2">
                {/* facebook icon  */}
                <a
                  className="a-no-style"
                  href="https://www.facebook.com/Visual-COGS-100885129453357"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="icon-box-home facebook-bg">
                    <i className="fab fa-facebook-f home-brands-icons"></i>
                  </div>
                </a>
                {/* linkedin icon  */}
                <a
                  className="a-no-style"
                  href="https://www.linkedin.com/company/visual-cogs"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="icon-box-home linkedin-bg">
                    <i className="fab fa-linkedin-in home-brands-icons"></i>
                  </div>
                </a>
                {/* twitter icon  */}
                <a
                  className="a-no-style"
                  href="https://twitter.com/VisualCOGS"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="icon-box-home twitter-bg">
                    <Twitter />
                  </div>
                </a>
                {/* instagram icon  */}
                <a
                  className="a-no-style"
                  href="https://www.instagram.com/visual__cogs/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="icon-box-home instagram-bg">
                    <i className="fab fa-instagram home-brands-icons"></i>
                  </div>
                </a>
                {/* email icon  */}
                <a
                  className="a-no-style"
                  href="mailto:customerrelations@visualcogs.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="icon-box-home mail-bg">
                    <i className="fas fa-envelope home-brands-icons"></i>
                  </div>
                </a>
              </div>
            </div>
            {/* right box  */}
            <div
              className="col-11 col-lg-7 text-lg-start text-center order-first order-lg-last mx-auto"
              data-aos="fade-down"
            >
              <div className="right-home-title mb-3 mt-5">
                Visualize All Your E-commerce Analytics <br />
                In One Place
              </div>
              {/* <div className="landing-page-deatils ">
								Among about seven million sellers, only 1.9 million sellers are
								actively selling on the marketplace.
							</div> */}
              <div className="responsive-grid mb-5">
                <div className="d-flex tool-bottom mt-3">
                  <div className="img-tick">
                    <img src={tick} width="20px" alt="Tick" />
                  </div>
                  <div className="landing-tick-text mx-3">Increase sales</div>
                </div>
                <div className="d-flex tool-bottom mt-3">
                  <div className="img-tick">
                    <img src={tick} width="20px" alt="Tick" />
                  </div>
                  <div className="landing-tick-text mx-3">Save time</div>
                </div>
                <div className="d-flex tool-bottom mt-3">
                  <div className="img-tick">
                    <img src={tick} width="20px" alt="Tick" />
                  </div>
                  <div className="landing-tick-text mx-3">Plan ahead</div>
                </div>
                <div className="d-flex tool-bottom mt-3">
                  <div className="img-tick">
                    <img src={tick} width="20px" alt="Tick" />
                  </div>
                  <div className="landing-tick-text mx-3">Grow fast</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Home);
