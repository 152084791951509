import React from "react";
import circle from "../../Images/circle1.svg";
import "./Principle.css";
const Principle = () => {
  return (
    <div className="principle p-4">
      <div className="container mt-5 mb-5">
        <div className="row align-items-center">
          <div
            className="col-lg-6 col-11 border-radius p-4 mx-auto"
            data-aos="fade-up"
          >
            <img src={circle} className="circle-position" alt="Circle" />
            <div className="principle-title mt-5  mb-3 text-center">
              Our Principles
            </div>
            <div className="principle-deatils text-center p-4">
              Visual COGS is a customer-centric and employee-driven organization
              guided by our core values. We believe that we can create a
              successful experience for vendors and sellers by embodying these
              principles.
            </div>
          </div>
          <div className="col-lg-6 col-11 mx-auto">
            <div className="divider mb-5" data-aos="zoom-in">
              <div className="innovation mb-2 text-lg-start text-center">
                Innovation
              </div>
              <div className="right-deatils mb-3">
                We believe that growth is infinite. We work towards continuous
                improvement through frequent customer feedback and creative
                problem-solving.
              </div>
            </div>

            <div className="divider mb-5" data-aos="zoom-in">
              <div className="innovation mb-2 text-lg-start text-center">
                Collaboration
              </div>
              <div className="right-deatils mb-3">
                Our teammates row together, creating an effective and healthy
                working environment. We embrace diversity and the opportunity to
                work with exceptional people from around the globe.
              </div>
            </div>
            <div className="divider mb-5" data-aos="zoom-in">
              <div className="innovation mb-2 text-lg-start text-center">
                Customer-centric Team
              </div>
              <div className="right-deatils mb-3">
                We believe that our customers are the experts. New features are
                regularly introduced via customer feedback and requests. We
                empathize with our customers, help shoulder their problems, and
                earn trust to form long-term relationships.
              </div>
            </div>
            <div className="divider mb-5" data-aos="zoom-in">
              <div className="innovation mb-2 text-lg-start text-center">
                Setting Goals and Yielding Results
              </div>
              <div className="right-deatils mb-3">
                One of our many objectives is to minimize complexity and
                maximize success for our customers. We are constantly raising
                the bar, striving to deliver the best product, and holding
                ourselves accountable for the results we provide.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Principle;
