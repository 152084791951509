import React from "react";
import { connect } from "react-redux";
import { BackendAPIs, getBaseApi, postCall } from "../../constants";
import tick from "../Images/tick3.png";
import { withRouter } from "react-router-dom";
let priceMap = {
  "Costco Standard": {
    price: "$900",
    append: " / month",
    prepend: "Starting From ",
  },
  "Costco Premium": {
    price: "$1,000",
    append: " / month",
    prepend: "Starting From ",
  },
  "Costco Complete": { price: "Let's Discuss", append: "", prepend: " " },
  Standard: {
    price: "Let's Discuss",
    append: "",
    prepend: "",
  },
  Complete: {
    price: "Let's Discuss",
    append: "",
    prepend: "",
  },
  Custom: {
    price: "Let's Discuss",
    append: "",
    prepend: "",
  },
};
class Package extends React.Component {
  state = {
    plans: [],
    title: "",
  };

  componentDidMount() {
    postCall(getBaseApi() + BackendAPIs.generic.getPlans, {
      type: this.props.type,
    }).then((resp) => {
      this.setState({ plans: resp.plans, title: resp.title });
    });
  }

  render() {
    return (
      <div className="pricing p-4 mt-5 mb-5">
        <div className="container mt-5 mb-5">
          <div className="package-title text-center mt-5 mb-5">Pricing</div>

          {/* pricing grid */}
          <div className="pricing-grid mt-5 mb-5" data-aos="zoom-in">
            {this.state.plans.map((d, i) => {
              return (
                <div
                  key={i}
                  className={
                    "pricing-grid-item p-3" +
                    (i === 1 ? " border-green shadow" : "")
                  }
                >
                  <div className="mb-5">
                    <div className="title-part border-bottom mb-3 p-3">
                      <div className="price-deatils">{d.name}</div>

                      {priceMap[d.name] ? (
                        <div className="price mb-3">
                          {priceMap[d.name].prepend}
                          <span className="me-2 color ms-2">
                            {priceMap[d.name].price}
                          </span>
                          {priceMap[d.name].append}
                        </div>
                      ) : null}
                    </div>
                    <div className="d-flex tool-bottom mt-3">
                      <div className="img-tick">
                        <img src={tick} width="20px" alt="Tick" />
                      </div>
                      <div className="tool-tick-text mx-3">
                        <span
                          className="color"
                          style={{ fontWeight: "bolder" }}
                        >
                          14 &nbsp;
                        </span>
                        Day Trial
                      </div>
                    </div>

                    {d.features.map((d1, i1) => {
                      return (
                        <div key={i1} className="d-flex tool-bottom mt-3">
                          <div className="img-tick">
                            <img src={tick} width="20px" alt="Tick" />
                          </div>
                          <div className="tool-tick-text mx-3">{d1}</div>
                        </div>
                      );
                    })}
                  </div>

                  {this.props.isLoggedIn ? (
                    <div className="button-part mb-5 mt-3 text-center">
                      <button
                        className={
                          (i === 1 ? "btn-withselect" : "btn-withoutselect") +
                          " w-100 d-flex justify-content-between align-items-center"
                        }
                        onClick={() => {
                          window.open(
                            "https://console.visualcogs.com",
                            "_blank"
                          );
                        }}
                      >
                        <span>Open Console</span>
                        <i className="fas fa-arrow-right"></i>
                      </button>
                    </div>
                  ) : (
                    <div className="button-part mb-5 mt-3 text-center">
                      <button
                        className={
                          (i === 1 ? "btn-withselect" : "btn-withoutselect") +
                          " w-100 d-flex justify-content-between align-items-center"
                        }
                        onClick={() => {
                          this.props.history.push("/request-a-demo");
                        }}
                      >
                        <span>Talk to Sales</span>
                        <i className="fas fa-arrow-right"></i>
                      </button>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Package)
);
