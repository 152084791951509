import React, { Component } from "react";

class AlexaPrivacyPolicy extends Component {
  render() {
    return (
      <>
        <div className="ion-header">
          <div className="ion-toolbar">
            <div className="ion-title">
              Visual COGS Amazon Alexa Skill Privacy Policy
            </div>
            <button
              className="subscribe shadow abs-right"
              onClick={() => {
                window.open("/", "_self");
              }}
            >
              GO TO HOME
            </button>
          </div>
        </div>

        <div className="ion-content">
          <div className="tc_content">
            <div className="tc_section">
              <p className="tc_para">
                Visual COGS (VC) operates as an Amazon Alexa Skill created by
                VisualCOGS.com. It is VC’s policy to respect your privacy
                regarding any information we may collect while operating. We use
                your Personal Information only to provide a personalized user
                experience. By enabling the skill, you agree to use of
                information in accordance with this policy.
              </p>
            </div>
            <div className="tc_section mt-3">
              <div className="tc_block">
                <div className="tc_subtitle">
                  Gathering of Personally-Identifying Information
                </div>
                <p className="tc_para">
                  Certain users to VC’s skill choose to interact with VC in ways
                  that require VC to gather personally-identifying information.
                  The amount and type of information that VC gathers depends on
                  the nature of the interaction. For example, we use the
                  provided access token to fetch information about the dollar
                  and unit sales metrics of your products. VC collects such
                  information only insofar as is necessary or appropriate to
                  fulfill the purpose of the users's interaction with VC. VC
                  does not disclose personally-identifying information other
                  than as described below. And users can always refuse to supply
                  personally-identifying information by not enabling the skill.
                </p>
              </div>

              <div className="tc_section mt-3">
                <div className="tc_block">
                  <div className="tc_subtitle">Data Retention Policy</div>
                  <p className="tc_para">
                    We will not retain any user provided data obtained through
                    this skill, with the exception of Log Data.
                  </p>
                </div>
              </div>
              <div className="tc_section mt-3">
                <div className="tc_block">
                  <div className="tc_subtitle">Log Data</div>
                  <p className="tc_para">
                    To be able to investigate issues with the skill, VC stores
                    logs. These logs may include personally-identifying
                    information which is not exposed, sold, or used besides the
                    tracking of issues.
                  </p>
                </div>
              </div>
              <div className="tc_section mt-3">
                <div className="tc_block">
                  <div className="tc_subtitle">
                    Protection of Certain Personally-Identifying Information
                  </div>
                  <p className="tc_para">
                    VC discloses potentially personally-identifying and
                    personally-identifying information only to those of its
                    employees, that (i) need to know that information in order
                    to process it on VC’s behalf, and (ii) that have agreed not
                    to disclose it to others. Some of those employees may be
                    located outside of your home country; by using VC’s skill,
                    you consent to the transfer of such information to them. VC
                    will not rent or sell potentially personally-identifying and
                    personally-identifying information to anyone. Other than to
                    its employees, as described above, VC discloses potentially
                    personally-identifying and personally-identifying
                    information only in response to a subpoena, court order, or
                    other governmental request, or when VC believes in good
                    faith that disclosure is reasonably necessary to protect the
                    property or rights of VC, third parties, or the public at
                    large. VC takes all measures reasonably necessary to protect
                    against the unauthorized access, use, alteration, or
                    destruction of potentially personally-identifying and
                    personally-identifying information.
                  </p>
                </div>
              </div>
              <div className="tc_section mt-3">
                <div className="tc_block">
                  <div className="tc_subtitle">Privacy Policy Changes</div>
                  <p className="tc_para">
                    Although most changes are likely to be minor, VC may change
                    its Privacy Policy from time to time, and at VC’s sole
                    discretion. VC encourages visitors to frequently check this
                    page for any changes to its Privacy Policy. Your continued
                    use of this skill after any change in this Privacy Policy
                    will constitute your acceptance of such change.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AlexaPrivacyPolicy;
