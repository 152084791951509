import React from "react";
import { BackendAPIs, getBaseApi, postCall } from "../../../constants";
// import { questions } from "./api";
import MyAccordion from "./MyAccordion";
class Accordion extends React.Component {
  state = {
    questions: [],
  };

  componentDidMount() {
    postCall(getBaseApi() + BackendAPIs.generic.getFAQs, {}).then((resp) => {
      this.setState({ questions: resp.FAQs });
    });
  }

  render() {
    return (
      <div className="accordion p-4" id="faq">
        <div className="container mt-5 mb-5">
          <div className="accordion-title mb-5 mt-5 text-center">
            Frequently Asked Questions
          </div>
          <div className="col-11 mx-auto">
            {this.state.questions.map((curElem, i) => {
              return <MyAccordion key={i} element={curElem} />;
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default Accordion;
