import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Home from "./Component/Landingpage/Home";
import Blog from "./Component/Blog/Blog";
import PricingPage from "./Component/Pricing/Pricing";
import FAQsPage from "./Component/Pricing/FAQs";
import About from "./Component/About/About";
import { actions, BackendAPIs, getBaseApi, postCall } from "./constants";
import { connect } from "react-redux";
import SingleResource from "./Component/SingleResource/SingleResource";
import PrivacyPolicy from "./Component/PrivacyPolicy/PrivacyPolicy";
import AlexaPrivacyPolicy from "./Component/AlexaPrivacyPolicy/AlexaPrivacyPolicy";
import NoMatch from "./Component/NoMatch/NoMatch";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import NewsletterNew from "./Component/NewsLetter/NewsletterNew";
import * as qs from "query-string";
import Landing from "./Component/Landing/Landing";

import "./Component/Home/Header.css";
import "./Component/Home/FooterWithCTA/Footer.css";
import "./Component/Home/Footer/Footer.css";
import Unsubscribe from "./Component/Unsubscribe/Unsubscribe";

function App(props) {
  let { openSubscription } = qs.parse(window.location.search);
  let [show, showModal] = useState(
    openSubscription && openSubscription === "Yes" ? true : false
  );
  window.addEventListener("message", (e) => {
    if (e.data && e.data.token && e.data.from === "console") {
      postCall(
        getBaseApi() + BackendAPIs.generic.validateJWT,
        { jwt: e.data.token },
        false,
        null,
        true,
        true
      ).then((d) => {
        if (d.validated) {
          localStorage.setItem("token-x", e.data.token);
          localStorage.setItem("valid-token", "Yes");
          localStorage.setItem("name", d.name);
          if (d.profilePic) {
            localStorage.setItem("profilePicture", d.profilePic);
          } else {
            localStorage.removeItem("profilePicture");
          }
        } else {
          localStorage.setItem("valid-token", "No");
          localStorage.removeItem("token-x");
          localStorage.removeItem("name");
          localStorage.removeItem("profilePicture");
        }
        let data = {
          isLoggedIn: d.validated,
          userName: d.name || null,
          profilePic: d.profilePic || null,
        };
        props && props.updateLogin && props.updateLogin(data);
      });
    } else if (e.data.from === "console") {
      localStorage.setItem("valid-token", "No");
      localStorage.removeItem("token");
      localStorage.removeItem("name");
      localStorage.removeItem("profilePicture");
      let data = {
        isLoggedIn: false,
        userName: null,
        profilePic: null,
      };
      props && props.updateLogin && props.updateLogin(data);
    }
  });

  return (
    <div className="App">
      {show ? (
        <Modal
          className="p-0 m-0 modal-margin-sm"
          show={show}
          onHide={() => {
            showModal(false);
          }}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body className="p-0 m-0">
            <div className="modal-actions">
              <i
                className="fa fa-times cursor-pointer cross-btn1 color-white"
                aria-hidden="true"
                onClick={() => {
                  showModal(false);
                }}
              ></i>
            </div>
            <NewsletterNew
              onHide={() => {
                showModal(false);
              }}
            />
          </Modal.Body>
        </Modal>
      ) : null}
      <button
        onClick={() => {
          showModal(true);
        }}
        className="button-0-3-5 button-d0-0-3-16 button_isShown-0-3-6"
      >
        <div className="contentWrapper-0-3-12">
          <span>Subscribe</span>
        </div>
        <div className="loadingWave-0-3-14"></div>
      </button>
      <Router>
        <Switch>
          <Route exact path="/">
            <Landing />
          </Route>
          <Route exact path="/home">
            <Redirect to="/" />
          </Route>
          <Route exact path="/plans">
            <PricingPage />
          </Route>
          <Route exact path="/resources">
            <Blog />
          </Route>
          <Route exact path="/about-us">
            <About />
          </Route>
          <Route exact path="/request-a-demo">
            <Home />
          </Route>
          <Route exact path="/faqs">
            <FAQsPage />
          </Route>

          {/* <Route exact path="/newsletter">
            <NewsLetter />
          </Route> */}
          {/* <Route exact path="/we-are-hiring">
            <WeAreHiring />
          </Route> */}
          <Route exact path="/unsubscribe/:id">
            <Unsubscribe />
          </Route>
          <Route exact path="/terms-and-conditions">
            <PrivacyPolicy />
          </Route>
          <Route exact path="/alexa-skill/privacy-policy">
            <AlexaPrivacyPolicy />
          </Route>
          <Route exact path="/resources/:id">
            <SingleResource />
          </Route>
          <Route exact path="/resources/:id/:title">
            <SingleResource />
          </Route>
          <Route>
            <NoMatch />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateLogin: (data) => dispatch({ type: actions.LOGIN_CHANGED, data }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
