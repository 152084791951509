import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import attractions from "../Assets/Images/img5_1.png";

const Section3 = (props) => {
  return (
    <div className="section3-parent container-fluid bg-grayish">
      <div className="section3-container container pt-5 spy-1">
        <div
          className="section3-heading flex-column text-center"
          data-aos="fade-up"
        >
          <h2 className="header1 fs-0 fw-bold">
            Do What You Do Best, <span className="text-grn">Drive Profits</span>
          </h2>
          <h2 className="header2 fs-4">
            Prioritize Critical Metrics and Seize Opportunities
          </h2>
        </div>
        <div className="section3-body sec3-grid spy-1">
          <div className="sec3-1st pb-5">
            <div className="left-content w-80">
              <div>
                <div
                  className="part-header text-wrap fs-1 text-left"
                  data-aos="fade-up"
                >
                  <h2 className="part-heading-bold fw-bold fs-2 mb-3">
                    Maximize Your <span className="text-grn">Time</span> and{" "}
                    <span className="text-grn">Earnings</span>, While We Handle
                    the Data
                  </h2>
                  <h3 className="part-heading-small fs-3">
                    From Complexity to Clarity
                  </h3>
                </div>
                <hr className="text-grn" />
                <div className="text-content" data-aos="fade-up">
                  <p className="text-p-line bg-grn p-2 fs-5 fw-bold">
                    Advanced Analytics for Trend Visualization
                  </p>
                  <p className="text-p-line bg-grn p-2 fs-5 fw-bold">
                    Intuitive Filters for Identifying Key Data Points
                  </p>
                  <p className="text-p-line bg-grn p-2 fs-5 fw-bold">
                    Custom Dashboards for Quick Insights
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="right-img">
            <img
              data-aos="zoom-in"
              src={attractions}
              className="section3-attractions-img"
              alt="graphics"
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Section3)
);
