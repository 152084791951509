import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import img from "../Assets/Images/img4_1.png";

const Section1 = (props) => {
  return (
    <div className="bg-grayish container-fluid sec1 pt-5 pb-5">
      <div className="container">
        <div className="sec1-grid spy-1">
          <div className="header-home col-10 col-lg-12 mx-auto">
            <h2
              className="text-start header-text mt-5 mb-4 fs-0_5"
              data-aos="fade-up"
            >
              The Never-Ending Struggle of Wrestling with Vendor Central Data
            </h2>
            <ul className="ul grid-1fr sec1-ul">
              <li
                className="bg-white fs-5 text-wrap"
                style={{ maxWidth: "80%" }}
              >
                Save Time Gathering Data - Free Up Your Time to Make Decisions
                That Matter
              </li>
              <li
                className="bg-lightgreen fs-5 text-wrap"
                style={{ maxWidth: "80%" }}
              >
                Looking to Maximize Your Revenue? Uncover Blind Spots and End
                Declining Sales
              </li>
              <li
                className="bg-white fs-5 text-wrap"
                style={{ maxWidth: "80%" }}
              >
                Reclaim Peace with Data Insight at Your Fingertips!
              </li>
            </ul>
          </div>
          <div data-aos="zoom-in" className="justify-self-end">
            {" "}
            <img src={img} className="mw-opt" alt="graphics"></img>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Section1)
);
