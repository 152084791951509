import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import jQuery from 'jquery';
import { ToastContainer } from 'react-toastify';
import { isDevEnv } from './constants';
import reducer from './Store/reducer';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { ReactComponent as Loading } from './Component/Images/loading.svg';

const store = createStore(reducer);


ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
      <div id="loading-modal" className="modal">
        <div className="svgContainer" style={{ display: 'none' }}>  <Loading /> </div>
      </div>
      <div id="redirecting-modal" className="modal">
        <div className="please-wait">Please wait! Redirecting...</div>
      </div>
      <div className="modal" id="error-modal" tabIndex="-1" role="dialog" style={{ display: 'none' }}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modal-error-header">.</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { jQuery('body').removeClass('body-error') }}>
                <span aria-hidden="true" >&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p id="modal-error-body"></p>
            </div>
            <div className="modal-footer">
              <button type="button" style={{ padding: '5px 20px' }} className="btn btn-secondary" data-dismiss="modal" onClick={() => { jQuery('body').removeClass('body-error') }}>Ok</button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-left"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {<div className='h-0'>
        <iframe
          style={{ height: 0 }}
          id="jwt"
          src={isDevEnv() ? 'http://localhost:3001/jwt' : 'https://console.visualcogs.com/jwt'} title="jwt"></iframe>
      </div>}
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
