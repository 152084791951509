import jQuery from "jquery";
import { toast } from "react-toastify";

export const getBaseApi = function () {
  if (window.location.hostname === "localhost") {
    return window.location.origin.split("300")[0] + "5000";
  }
  return "https://api.visualcogs.com";
};

export const isDevEnv = function () {
  return window.location.hostname === "localhost";
};

export const BackendAPIs = {
  scheduleDemo: "/schedule-demo",
  subscribeNewsletter: "/subscribe-newletter",
  generic: {
    validateJWT: "/generic/validateJWT",
    blogs: {
      latestBlogs: "/generic/blogs/latestBlogs",
      featuredBlogs: "/generic/blogs/featuredBlogs",
      getSingleBlog: "/generic/blogs/getSingleBlog",
    },
    getPlans: "/generic/getPlans",
    getFAQs: "/generic/getFAQs",
    subscribe: "/generic/subscribe",
    joinWaitlist: "/generic/joinWaitlist",
    unsubscribe: "/generic/unsubscribe",
  },
};

const httpCalls = [];

export const postCall = (
  url,
  data,
  downloadRequest,
  fileName,
  ignoreException,
  stoploading,
  additionalHeaders
) => {
  if (!stoploading) {
    httpCalls.push(1);
    jQuery("body").addClass("loading");
  }
  let headers = {};
  if (additionalHeaders) {
    headers = { ...additionalHeaders };
  }
  if (localStorage.getItem("token")) {
    headers["x-access-token"] = localStorage.getItem("token");
    headers["x-active-account"] = localStorage.getItem("account");
  }
  const contentType = "application/json; charset=utf-8";
  const type = "post";
  return jQuery
    .ajax({
      url,
      data: JSON.stringify(data),
      headers,
      contentType,
      type,
      xhr: () => {
        let xhr = new XMLHttpRequest();
        if (downloadRequest) {
          xhr.responseType = "arraybuffer";
        }
        return xhr;
      },
    })
    .then(
      (resp) => {
        if (!stoploading) {
          httpCalls.pop();
          if (!httpCalls.length) {
            jQuery("body").removeClass("loading");
          }
        }
        if (downloadRequest) {
          try {
            var blob = new Blob([resp]);

            var downloadUrl = URL.createObjectURL(blob);
            var a = document.createElement("a");
            a.href = downloadUrl;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          } catch (exc) {
            console.log(
              "Save Blob method failed with the following exception."
            );
            console.log(exc);
          }
        }
        return resp;
      },
      (err) => {
        if (!stoploading) {
          httpCalls.pop();
          if (!httpCalls.length) {
            jQuery("body").removeClass("loading");
          }
        }
        if (err.responseJSON && err.responseJSON.logout) {
          localStorage.removeItem("token");
          localStorage.removeItem("email");
          localStorage.removeItem("name");
          localStorage.removeItem("account");
          window.location.reload();
          return;
        }

        if (!ignoreException) {
          let message =
            "Oopppss! Something Went Wrong...Please Try again Later";
          let header = "Server Error";
          if (err.status && err.statusText) {
            header = err.status + " - " + err.statusText;
          }
          if (err.responseJSON && err.responseJSON.message) {
            message = err.responseJSON.message;
          }

          jQuery("#modal-error-header").text(header);
          jQuery("#modal-error-body").text(message);
          jQuery("body").addClass("body-error");
        }

        throw err;
      }
    );
};

export const actions = {
  LOGIN_CHANGED: "LOGIN_CHANGED",
};

export const subscribe = (email, cb) => {
  postCall(getBaseApi() + BackendAPIs.generic.subscribe, { email }).then(
    (resp) => {
      toast("Thanks for subscribing Visual COGS newsletters.");
      cb && cb();
    }
  );
};
