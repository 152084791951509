import React, { Component } from "react";
import "./NewsletterNew.css";
import { BackendAPIs, getBaseApi, postCall } from "../../constants";
import { toast } from "react-toastify";
import header from "../Assets/Images/img15.png";

class NewsletterNew extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      validEmail: false,
    };
  }

  subscribeNewsletter() {
    postCall(
      getBaseApi() + BackendAPIs.generic.subscribe,
      {
        email: this.state.email,
      },
      false,
      null,
      true
    ).then(
      (resp) => {
        this.setState({
          email: "",
          validEmail: false,
        });
        toast("You have successfully subscribed to Visual COGS newsletter.");
        this.props.onHide && this.props.onHide();
      },
      (err) => {
        let message = "Something went wrong";
        if (err.responseJSON && err.responseJSON.message) {
          message = err.responseJSON.message;
        }
        toast(`Error: [${message}].`);
      }
    );
  }

  render() {
    return (
      <div className="subscriber-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="fs-2 fw-bold">
                Keep up with Vendor Central Updates!
              </h2>
              <h3 className="fs-5 mb-4">
                Get the weekly newsletter, keeping thousands of vendors in the
                loop.
              </h3>
              <div class="input-group mb-1">
                <input
                  type="email"
                  class="form-control"
                  placeholder="Enter your email"
                  aria-label="Enter your email"
                  value={this.state.email}
                  onChange={(e) => {
                    let val = e.target.value;
                    let isValid = false;
                    if (
                      val.trim() &&
                      // eslint-disable-next-line
                      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(val)
                    ) {
                      isValid = true;
                    }
                    this.setState({
                      email: val,
                      validEmail: isValid,
                    });
                  }}
                />
                <div>
                  <div class="input-group-append bg-black">
                    <button
                      disabled={!this.state.validEmail}
                      class="input-group-text btn btn-dark"
                      id="basic-addon"
                      onClick={() => {
                        this.subscribeNewsletter();
                      }}
                    >
                      Try it
                    </button>
                  </div>
                </div>
              </div>
              <p className="fs-8 mb-4">
                Unsubscribe any time, no hard feelings.
              </p>
              <h4 className="fs-6">Loved by our Vendors!</h4>
              <div className="m-auto news-img mb-2">
                <img src={header} className="w-100" alt="graphs"></img>
              </div>
              <h3 className="fs-5">
                "Visual COGS consistently keeps you updated; its monthly new
                features email is an added bonus."
              </h3>
              <div className="fs-8">
                -- Kristina Ford, Head of Sales E-Commerce
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NewsletterNew;
