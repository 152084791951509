import React from "react";
import Navbar from "../Shared/Navbar/Navbar";
import Shared from "../Shared/Shared";
import Accordion from "./Accordion/Accordion";
import "../Home/Pricing/Pricing.css";
import Subscribe from "./Subscribe";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import Footer from "../Landing/Footer";

const FAQsPage = () => {
  return (
    <div className="pricing">
      <Helmet>
        <title>Plans | Select The Best Plan</title>
        <meta
          name="description"
          content="Visual COGS provides three pricing tiers for Amazon and Costco Vendors to help choose a plan that fits you. Request a Demo Today!"
        />
      </Helmet>
      <Navbar />
      <Shared />
      <div className="container">
        <div className="row align-items-center">
          <div
            className="col-11 col-lg-6 mx-auto mt-lg-0 mt-5 mb-3"
            data-aos="fade-up"
          >
            <div
              className="img-fluid d-lg-none w-100 career-image size-que"
              data-aos="flip-left"
            >
              <i className="fa fa-question"></i>
            </div>

            <h1 className="products-title text-center text-lg-start mb-5">
              Need <span>Help ?</span>
            </h1>

            <p className="mb-3 text-justify">
              Got a question? We're here to answer! If you don't see your
              question here, drop us a line on{" "}
              <a href="mailto:customerrelations@visualcogs.com">
                CustomerRelations@VisualCOGS.com
              </a>
            </p>
          </div>
          <div className="col-11 col-lg-6 mx-auto" data-aos="flip-left">
            <div className="img-fluid d-none d-lg-block w-100 career-image size-que">
              <i className="fa fa-question"></i>
            </div>
          </div>
        </div>
      </div>
      <Accordion />
      <Subscribe />
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(FAQsPage);
