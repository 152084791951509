import React from "react";
import bolg from "../../Images/vcogs1.svg";
import user from "../../Images/fbk-user1.png";
import mail from "../../Images/envelope.svg";
import "./Latest.css";
import {
  BackendAPIs,
  getBaseApi,
  postCall,
  subscribe,
} from "../../../constants";
import BlogModal from "../../BlogComponent/BlogModal";
import moment from "moment";
class Latest extends React.Component {
  state = {
    blogs: [],
    show: false,
    currentBlog: {},
    email: "",
  };

  onHide() {
    this.setState({ show: false });
  }

  componentDidMount() {
    postCall(getBaseApi() + BackendAPIs.generic.blogs.latestBlogs, {
      offset: 0,
      limit: 2,
    }).then((resp) => {
      this.setState({
        blogs: resp.blogs,
      });
    });
  }

  render() {
    return (
      <div className="latest-blog p-4 bg-greenish">
        <div className="container mt-5 mb-5">
          <div className="row align-items-center">
            <div className="col-12 col-lg-6 mx-auto">
              <div className="grid-box">
                {this.state.blogs.map((d, i) => {
                  let blog = d || {};
                  return (
                    <div
                      key={i}
                      // onClick={() => {
                      // 	this.setState({
                      // 		show: true,
                      // 		currentBlog: blog
                      // 	});
                      // }}
                      onClick={() => {
                        window.open(
                          "/resources/" +
                            blog._id +
                            "/" +
                            encodeURIComponent(
                              blog.urlTitle || blog.title || ""
                            ),
                          "_blank"
                        );
                      }}
                      className="grid-box-slider carousel-item active p-2 cursor-pointer"
                    >
                      <div className="top-part mb-4 d-flex justify-content-between  align-items-center">
                        <div className="blog-img">
                          <img
                            src={blog.smallImagePath || blog.imagePath || bolg}
                            alt="Blog"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                      <div className="bottom-title mb-3">{blog.title}</div>
                      <div className="blog-owner align-items-center d-flex mb-3">
                        <div className="user me-3">
                          <img
                            src={blog.bloggerPic || user}
                            alt="Blog Author"
                            className="img-fluid blogger-img"
                          />
                        </div>
                        <div className="extra-text d-flex justify-content-between align-items-center">
                          <span className="">
                            {blog.bloggerName || "Admin"}
                          </span>
                          <span className="start mx-3">|</span>
                          <span>
                            {blog.createdOn
                              ? moment(blog.createdOn).format("MMM DD, YYYY")
                              : "Nov 5, 2018"}
                          </span>
                        </div>
                      </div>
                      <div className="bottom-deatils mb-3">
                        {blog.shortDesc
                          ? blog.shortDesc.substring(0, 100) + "..."
                          : `There are many variations of passages of Lorem Ipsum available, but the majority have`}{" "}
                        <button className="btn btn-link inline-block btn-sm read-more-btn">
                          Read More
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-lg-5 text-lg-start text-center col-11 mx-auto">
              <div className="latest-blog-title text-center text-lg-start mt-5 mt-lg-0 mb-3">
                Latest Blog Post
              </div>
              <div className="latest-blog-deatils mb-3">
                Subscribe to get our latest blog posts as they come out. We
                don't do spam, just up-to-date information on e-commerce trends
                and opportunities.
              </div>
              <div className="input-btn-width shadow p-2 d-flex mb-4 w-100 d-none">
                <img src={mail} alt="Mail" className="ms-2 img-fluid" />
                <input
                  type="text"
                  className="input-subscribe"
                  placeholder="Enter Your Email"
                  value={this.state.email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
                <button
                  className="subscribe wd-450-none"
                  disabled={
                    !this.state.email ||
                    !this.state.email.trim ||
                    // eslint-disable-next-line
                    !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                      this.state.email
                    )
                  }
                  onClick={() => {
                    subscribe(this.state.email, () => {
                      this.setState({ email: "" });
                    });
                  }}
                >
                  Subscribe
                </button>
              </div>

              <button
                className="subscribe shadow w-100 wd-450-block d-none"
                disabled={
                  !this.state.email ||
                  !this.state.email.trim ||
                  // eslint-disable-next-line
                  !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                    this.state.email
                  )
                }
                onClick={() => {
                  subscribe(this.state.email, () => {
                    this.setState({ email: "" });
                  });
                }}
              >
                Subscribe
              </button>
            </div>
          </div>
        </div>
        <BlogModal
          show={this.state.show}
          onHide={this.onHide.bind(this)}
          blogObj={this.state.currentBlog}
        />
      </div>
    );
  }
}

export default Latest;
