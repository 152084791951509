import React, { Component } from "react";
import { ReactComponent as X404 } from "./X404.svg";
import { Helmet } from 'react-helmet';
export default class NoMatch extends Component {
    render() {
        return (
            <div className="parent-100vh">
                <Helmet>
                    <title>Visual COGS | 404</title>
                    <meta name="description"
                        content="404. Page Not Found" />
                </Helmet>
                <main>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 align-self-center">
                                <X404 />
                            </div>
                            <div className="col-md-6 align-self-center">
                                <h1>404</h1>
                                <h2>UH OH! You're lost.</h2>
                                <p>The page you are looking for does not exist.
                                    How you got here is a mystery. But you can click the button below
                                    to go back to the homepage.
                                </p>
                                <button className="subscribe shadow"
                                    onClick={() => {
                                        window.open('/', '_self');
                                    }}
                                >GO TO HOME</button>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}