import React from "react";
import { HashLink } from "react-router-hash-link";
import { Nav } from "react-bootstrap";
import logo from "../Assets/Images/vcogs-logo.png";
import bdge1 from "../Assets/Images/bdge1.png";

import { Link, NavLink } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitterSquare,
  faLinkedin,
  faFacebook,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

import { ReactComponent as Twitter } from "../Images/x-twitter.svg";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const Footer = (props) => {
  return (
    <footer className="footer-alt">
      <div className="container footer-padding2-alt">
        <div className="d-lg-none text-center">
          <Link to="/">
            <img
              src={logo}
              alt="Visual COGS Logo"
              className="img-fluid mb-3 footer-logo"
            />
          </Link>
          <div className="logo-bottom-text">Visualize Growth with Insights</div>
          <div>
            <img
              onClick={() => {
                window.open(
                  "https://advertising.amazon.com/partners/directory/details/amzn1.ads1.ma1.6c0mir4tiwadoify2iw2kww4w/Visual-COGS/",
                  "_blank"
                );
              }}
              src={bdge1}
              alt="Amazon Ad"
              className="img-fluid mb-3 footer-bdge cursor-pointer"
            />
          </div>
        </div>
        <hr className="d-lg-none" />
        <div className="row footer-grid2-alt1 px-4">
          <div className="footer-col d-none d-lg-block">
            <Link to="/">
              <img
                src={logo}
                alt="Visual COGS Logo"
                className="img-fluid mb-3 footer-logo"
              />
            </Link>
            <div className="logo-bottom-text">
              Visualize Growth with Insights
            </div>
            <div>
              <img
                onClick={() => {
                  window.open(
                    "https://advertising.amazon.com/partners/directory/details/amzn1.ads1.ma1.6c0mir4tiwadoify2iw2kww4w/Visual-COGS/",
                    "_blank"
                  );
                }}
                src={bdge1}
                alt="Amazon Ad"
                className="img-fluid mb-3 footer-bdge cursor-pointer"
              />
            </div>
          </div>
          <div className="footer-col">
            <ul>
              <h5>Company</h5>
              <li>
                <Nav.Link
                  as={HashLink}
                  className="link p-0"
                  to="/about-us#story"
                >
                  Our story
                </Nav.Link>
              </li>
              <li>
                <Nav.Link
                  as={HashLink}
                  className="link p-0"
                  to="/resources#blog"
                >
                  Resources
                </Nav.Link>
              </li>
            </ul>
          </div>
          <div className="footer-col">
            <ul>
              <h5>Product</h5>
              {!props.isLoggedIn ? (
                [
                  <li key={0}>
                    <a
                      href="https://console.visualcogs.com/signin"
                      className="link"
                    >
                      Login
                    </a>
                  </li>,
                  <li key={1}>
                    <NavLink className="link" to="/request-a-demo" key={1}>
                      Request a Demo
                    </NavLink>
                  </li>,
                ]
              ) : (
                <li>
                  <a
                    href="https://console.visualcogs.com"
                    target="_blank"
                    rel="noreferrer"
                    className="link"
                  >
                    My Account
                  </a>
                </li>
              )}
              <li>
                <NavLink className="link" to="/plans">
                  Pricing
                </NavLink>
              </li>
            </ul>
          </div>

          <div className="footer-col">
            <ul>
              <h5>Help Centre</h5>
              <li>
                <a className="link" href="/faqs">
                  {" "}
                  FAQs
                </a>
              </li>
              <li>
                <a className="link" href="/terms-and-conditions">
                  {" "}
                  Terms & Conditions
                </a>
              </li>
            </ul>
          </div>

          <div className="footer-col d-none d-lg-block">
            <ul>
              <h5>Contact</h5>
              <div className="last-deatils mb-3">Reach out here:</div>
              <div className="icon-footer-grid">
                <a
                  href="https://www.facebook.com/Visual-COGS-100885129453357"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faFacebook}
                    className="facebook"
                  ></FontAwesomeIcon>
                </a>
                <a
                  href="https://www.linkedin.com/company/visual-cogs"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faLinkedin}
                    className="linkedin"
                  ></FontAwesomeIcon>
                </a>
                <a
                  href="https://twitter.com/VisualCOGS"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Twitter className="twitter" />
                </a>
                <a
                  href="https://www.instagram.com/visual__cogs/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faInstagram}
                    className="instagram"
                  ></FontAwesomeIcon>
                </a>
                <a
                  href="mailto:customerrelations@visualcogs.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className="mail"
                  ></FontAwesomeIcon>
                </a>
              </div>
            </ul>
          </div>
        </div>
        <hr className="d-lg-none" />
        <div className="d-lg-none text-center">
          <ul>
            <h5>Contact</h5>
            <div className="last-deatils mb-3">Reach out here:</div>
            <div className="icon-footer-grid">
              <a
                href="https://www.facebook.com/Visual-COGS-100885129453357"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  icon={faFacebook}
                  className="facebook"
                ></FontAwesomeIcon>
              </a>
              <a
                href="https://www.linkedin.com/company/visual-cogs"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  icon={faLinkedin}
                  className="linkedin"
                ></FontAwesomeIcon>
              </a>
              <a
                href="https://twitter.com/VisualCOGS"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  icon={faTwitterSquare}
                  className="twitter"
                ></FontAwesomeIcon>
              </a>
              <a
                href="https://www.instagram.com/visual__cogs/"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  icon={faInstagram}
                  className="instagram"
                ></FontAwesomeIcon>
              </a>
              <a
                href="mailto:customerrelations@visualcogs.com"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className="mail"
                ></FontAwesomeIcon>
              </a>
            </div>
          </ul>
        </div>
        <div className="copyright-part text-center p-3 border-top">
          Copyright © 2022. Visual COGS
        </div>
      </div>
    </footer>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Footer));
