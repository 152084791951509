import React from "react";
import Navbar from "../Shared/Navbar/Navbar";
import Shared from "../Shared/Shared";
import "./SingleResource.css";
import { withRouter } from "react-router-dom";
import { BackendAPIs, getBaseApi, postCall } from "../../constants";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import BlogBody from "../Shared/BlogBody";
import user from "../Images/fbk-user1.png";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import bolg from "../Images/vcogs1.svg";
import moment from "moment";
import Footer from "../Landing/Footer";
import { Button, Form, InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";

let bio = {
  Admin: (
    <div>
      <p>
        Thanks for reading the blog. For any query or suggestion please mail us
        on <a href="mailto:admin@visualcogs.com">admin@visualcogs.com</a>.
      </p>
    </div>
  ),
  "Debby Smith": (
    <div>
      <p>
        Debby Smith is the Marketing Manager at Visual COGS. She has over 7
        years of experience in marketing and business consultation. She has a
        passion for writing informational blogs, and a strong belief that coffee
        is the most important fuel for building a business.
      </p>
      <div>
        Connect with Debby on{" "}
        <a
          href="https://www.linkedin.com/in/debby-smith-106662227/"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon
            icon={faLinkedin}
            className="linkedin"
          ></FontAwesomeIcon>
        </a>{" "}
        OR{" "}
        <a href="mailto:debbysmith@visualcogs.com">
          <FontAwesomeIcon icon={faEnvelope} className="mail"></FontAwesomeIcon>
        </a>
      </div>
    </div>
  ),
  "Brian Murphy": (
    <div>
      <p>
        Brian Murphy is a storyteller, he's a marketer, he's a force to be
        reckoned with.
      </p>
      <div>
        Connect with Brian on{" "}
        <a href="mailto:BrianMurphy@VisualCOGS.com">
          <FontAwesomeIcon icon={faEnvelope} className="mail"></FontAwesomeIcon>
        </a>
      </div>
    </div>
  ),
};
class SingleBlog extends React.Component {
  state = {
    blogObj: null,
    blogs: [],
    email: "",
    validEmail: false,
    left: 0,
  };

  componentDidMount() {
    let id = this.props.match.params.id;
    postCall(getBaseApi() + BackendAPIs.generic.blogs.getSingleBlog, {
      id,
    }).then((resp) => {
      this.setState({ blogObj: resp.blog });
    });

    postCall(getBaseApi() + BackendAPIs.generic.blogs.latestBlogs, {}).then(
      (resp) => {
        this.setState({
          blogs: resp.blogs,
        });
      }
    );
  }

  render() {
    return (
      <div className="products font-arial">
        <Helmet>
          <title>
            Visual COGS | Learn from the Best to Stay Ahead in the Marketplace
          </title>
          <meta
            name="description"
            content="At Visual COGS, our values are more than a list of ideals to improve our corporate image. We’re dedicated to building an inclusive culture that reflects what’s important to our employees and the customers we serve."
          />
          <link rel="canonical" href="https://www.visualcogs.com/resources" />
        </Helmet>
        <Navbar />
        <Shared />
        <div className="split-grid">
          <div className="blog-content">
            <div className="container">
              <div className="row align-items-center">
                {this.state.blogObj ? (
                  <BlogBody
                    blogObj={this.state.blogObj}
                    className="single-blog-page"
                  />
                ) : (
                  <p>Blog not found.</p>
                )}
              </div>
            </div>
            <hr />
            {this.state.blogObj ? (
              <div className="container">
                <div className="blog-owner align-items-center d-flex mb-3">
                  <div className="user me-3">
                    <img
                      src={this.state.blogObj.bloggerPic || user}
                      alt="Blog Author"
                      className="img-fluid blogger-img"
                    />
                  </div>
                  <div className="extra-text d-flex justify-content-between align-items-center">
                    <span className="">
                      {this.state.blogObj.bloggerName || "Admin"}
                    </span>
                  </div>
                </div>
                {bio[this.state.blogObj.bloggerName] || bio["Admin"]}
                <br />
              </div>
            ) : null}
          </div>
          <div className="blogs-items-wrapper hide-1">
            <div className="text-end mt-3 mb-3 me-5">
              <button
                className="button-pev shadow me-3"
                disabled={this.state.left === 0}
                onClick={() => {
                  this.setState({
                    left: this.state.left - 5,
                  });
                }}
              >
                <i className="fas fa-angle-left"></i>
              </button>
              <button
                className="button-next shadow"
                disabled={this.state.left + 5 >= this.state.blogs.length}
                onClick={() => {
                  this.setState({
                    left: this.state.left + 5,
                  });
                }}
              >
                <i className="fas fa-angle-right"></i>
              </button>
            </div>
            {this.state.blogs.map((d, i) => {
              let blog = d || {};
              return this.state.blogObj?._id === blog._id ||
                i < this.state.left ||
                i > this.state.left + 5 ? null : (
                <div
                  key={i}
                  className="blogs-items-right cursor-pointer"
                  onClick={() => {
                    window.location.href =
                      "/resources/" +
                      blog._id +
                      "/" +
                      encodeURIComponent(blog.urlTitle || blog.title || "");
                  }}
                >
                  <div className="blog-img1">
                    <img
                      src={blog.smallImagePath || blog.imagePath || bolg}
                      alt="Blog"
                      className="img-fluid"
                    />
                  </div>
                  <div className="bottom-title mb-1">
                    <div className="dull">
                      {moment(blog.createdOn).format("MMM DD, YYYY")}
                    </div>
                    <div>{blog.title}</div>
                    <div className="bottom-deatils1 mb-3">
                      {blog.shortDesc
                        ? blog.shortDesc.substring(0, 100) + "..."
                        : `Visual COGS | Visualize Growth with Insights`}
                      <button className="btn btn-link inline-block btn-sm read-more-btn">
                        Read More
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="bottom-box">
              <div className="inner-box">
                <InputGroup className="mb-3">
                  <Form.Control
                    aria-label="Example text with button addon"
                    aria-describedby="basic-addon1"
                    placeholder="Email"
                    value={this.state.email}
                    onChange={(e) => {
                      let val = e.target.value;
                      let isValid = false;
                      if (
                        val.trim() &&
                        // eslint-disable-next-line
                        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                          val
                        )
                      ) {
                        isValid = true;
                      }
                      this.setState({
                        email: val,
                        validEmail: isValid,
                      });
                    }}
                  />
                  <Button
                    variant="outline-secondary"
                    id="button-addon1"
                    className="right-btn1"
                    disabled={!this.state.validEmail}
                    onClick={() => {
                      postCall(
                        getBaseApi() + BackendAPIs.generic.subscribe,
                        {
                          email: this.state.email,
                        },
                        false,
                        null,
                        true
                      ).then(
                        (resp) => {
                          this.setState({
                            email: "",
                            validEmail: false,
                          });
                          toast(
                            "You have successfully subscribed to Visual COGS newsletter."
                          );
                          this.props.onHide && this.props.onHide();
                        },
                        (err) => {
                          let message = "Something went wrong";
                          if (err.responseJSON && err.responseJSON.message) {
                            message = err.responseJSON.message;
                          }
                          toast(`Error: [${message}].`);
                        }
                      );
                    }}
                  >
                    Submit
                  </Button>
                </InputGroup>
                <div className="fs-1">
                  Making selling on Amazon{" "}
                  <span className="highlight-text">easier for Vendors.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SingleBlog)
);
