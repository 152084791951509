import React from "react";
import { Redirect, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { BackendAPIs, getBaseApi, postCall } from "../../constants";
import Landing from "../Landing/Landing";

class Unsubscribe extends React.Component {
  state = {
    success: false,
  };

  componentDidMount() {
    let id = this.props.match.params.id;
    postCall(getBaseApi() + BackendAPIs.generic.unsubscribe, { id }).then(
      (d) => {
        toast("You have successfully unsubscribed to Visual COGS newsletters.");
        this.setState({ success: true });
      }
    );
  }

  render() {
    return this.state.success ? <Redirect to={"/"} /> : <Landing />;
  }
}

export default withRouter(Unsubscribe);
