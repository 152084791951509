import React, { Component } from "react";

class PrivacyPolicy extends Component {
  render() {
    return (
      <>
        <div className="ion-header">
          <div className="ion-toolbar">
            <div className="ion-title">Our Terms & Conditions</div>
            <button
              className="subscribe shadow abs-right"
              onClick={() => {
                window.open("/", "_self");
              }}
            >
              GO TO HOME
            </button>
          </div>
        </div>

        <div className="ion-content">
          <div className="tc_content">
            <div className="tc_section">
              <div className="tc_section_title">
                Interpretation and Definitions
              </div>
              <div className="tc_block">
                <div className="tc_subtitle">Interpretation</div>
                <p className="tc_para">
                  The words of which the initial letter is capitalized have
                  meanings defined under the following conditions.
                </p>
                <p className="tc_para">
                  The following definitions shall have the same meaning
                  regardless of whether they appear in singular or in plural.
                </p>
              </div>
              <div className="tc_block">
                <div className="tc_subtitle">Definitions</div>
                <p className="tc_para">
                  For the purposes of these Terms and Conditions:
                </p>
                <ul className="tc_points">
                  <li>
                    <span className="tc_bold">Affiliate</span> means an entity
                    that controls, is controlled by or is under common control
                    with a party, where “control” means ownership of 50% or more
                    of the shares, equity interest or other securities entitled
                    to vote for election of directors or other managing
                    authority.
                  </li>
                  <li>
                    <span className="tc_bold">Account</span> means a unique
                    account created for You to access our Service or parts of
                    our Service.
                  </li>
                  <li>
                    <span className="tc_bold">Company</span> (referred to as
                    either “the Company”, “We”, “Us” or “Our” in this Agreement)
                    refers to Clear Hammer, LLC
                  </li>
                  <li>
                    <span className="tc_bold">Country</span> refers to: United
                    States
                  </li>
                  <li>
                    <span className="tc_bold">Service</span> refers to the
                    Website, Reports, Notifications, Alerts, and Correspondence.
                  </li>
                  <li>
                    <span className="tc_bold">Subscriptions</span> refer to the
                    Services or access to the Service offered on a subscription
                    basis by the Company to You.
                  </li>
                  <li>
                    <span className="tc_bold">Free Trial</span> refers to a
                    limited period of time that may be free when purchasing a
                    Subscription.
                  </li>
                  <li>
                    <span className="tc_bold">Terms and Conditions</span> (also
                    referred as “Terms”) mean these Terms and Conditions that
                    form the entire agreement between You and the Company
                    regarding the use of the Service.
                  </li>
                  <li>
                    <span className="tc_bold">
                      Third-party Social Media Service
                    </span>
                     means any services or content (including data, information,
                    products or services) provided by a third-party that may be
                    displayed, included or made available by the Service.
                  </li>
                  <li>
                    <span className="tc_bold">Website</span> refers to Visual
                    COGS, accessible from{" "}
                    <a href="https://visualcogs.com">VisualCOGS.com</a>
                  </li>
                  <li>
                    <span className="tc_bold">You</span> means the individual
                    accessing or using the Service, or the company, or other
                    legal entity on behalf of which such individual is accessing
                    or using the Service, as applicable.
                  </li>
                </ul>
              </div>
            </div>
            <div className="tc_section">
              <div className="tc_section_title">Acknowledgement</div>
              <div className="tc_block">
                <p className="tc_para">
                  These are the Terms and Conditions governing the use of this
                  Service and the agreement that operates between You and the
                  Company. These Terms and Conditions set out the rights and
                  obligations of all users regarding the use of the Service.
                </p>
                <p className="tc_para">
                  Your access to and use of the Service is conditioned on Your
                  acceptance of and compliance with these Terms and Conditions.
                  These Terms and Conditions apply to all visitors, users and
                  others who access or use the Service.
                </p>
                <p className="tc_para">
                  By accessing or using the Service You agree to be bound by
                  these Terms and Conditions. If You disagree with any part of
                  these Terms and Conditions then You may not access the
                  Service.
                </p>
              </div>
            </div>
            <div className="tc_section">
              <div className="tc_section_title">Subscriptions</div>
              <div className="tc_block">
                <div className="tc_subtitle">Subscription period</div>
                <p className="tc_para">
                  The Service or some parts of the Service are available only
                  with a paid Subscription. You will be billed in advance on a
                  recurring and periodic basis (such as daily, weekly, monthly
                  or annually), depending on the type of Subscription plan you
                  select when purchasing the Subscription.
                </p>
                <p className="tc_para">
                  At the end of each period, Your Subscription will
                  automatically renew under the exact same conditions unless You
                  cancel it or the Company cancels it.
                </p>
              </div>
              <div className="tc_block">
                <div className="tc_subtitle">Subscription cancellations</div>
                <p className="tc_para">
                  You may cancel Your Subscription renewal either through Your
                  Account settings page or by contacting the Company.
                </p>
                <p className="tc_para">
                  You will not receive a refund for the fees You already paid
                  for Your current Subscription period and You will be able to
                  access the Service until the end of Your current Subscription
                  period.
                </p>
              </div>
              <div className="tc_block">
                <div className="tc_subtitle">Billing</div>
                <p className="tc_para">
                  You shall provide the Company with accurate and complete
                  billing information including full name, address, state, zip
                  code, telephone number, and a valid payment method
                  information.
                </p>
                <p className="tc_para">
                  Should automatic billing fail to occur for any reason, the
                  Company will issue an electronic invoice indicating that you
                  must proceed manually, within a certain deadline date, with
                  the full payment corresponding to the billing period as
                  indicated on the invoice.
                </p>
              </div>
              <div className="tc_block">
                <div className="tc_subtitle">Fee Changes</div>
                <p className="tc_para">
                  The Company, in its sole discretion and at any time, may
                  modify the Subscription fees. Any Subscription fee change will
                  become effective at the end of the then-current Subscription
                  period.
                </p>
                <p className="tc_para">
                  The Company will provide You with reasonable prior notice of
                  any change in Subscription fees to give You an opportunity to
                  terminate Your Subscription before such change becomes
                  effective.
                </p>
                <p className="tc_para">
                  Your continued use of the Service after the Subscription fee
                  change comes into effect constitutes Your agreement to pay the
                  modified Subscription fee amount.
                </p>
              </div>
              <div className="tc_block">
                <div className="tc_subtitle">Refunds</div>
                <p className="tc_para">
                  Except when required by law, paid Subscription fees are
                  non-refundable.
                </p>
                <p className="tc_para">
                  Certain refund requests for Subscriptions may be considered by
                  the Company on a case-by-case basis and granted at the sole
                  discretion of the Company.
                </p>
              </div>
              <div className="tc_block">
                <div className="tc_subtitle">Free Trial</div>
                <p className="tc_para">
                  The Company may, at its sole discretion, offer a Subscription
                  with a Free trial for a limited period of time.
                </p>
                <p className="tc_para">
                  You may be required to enter Your billing information in order
                  to sign up for the Free trial.
                </p>
                <p className="tc_para">
                  If You do enter Your billing information when signing up for a
                  Free trial, You will not be charged by the Company until the
                  Free trial has expired. On the last day of the Free trial
                  period, unless You cancelled Your Subscription, You will be
                  automatically charged the applicable Subscription fees for the
                  type of Subscription You have selected.
                </p>
                <p className="tc_para">
                  At any time and without notice, the Company reserves the right
                  to (i) modify the terms and conditions of the Free trial
                  offer, or (ii) cancel such Free trial offer.
                </p>
              </div>
            </div>
            <div className="tc_section">
              <div className="tc_section_title">User Accounts</div>
              <div className="tc_block">
                <p className="tc_para">
                  When You create an account with Us, You must provide Us
                  information that is accurate, complete, and current at all
                  times. Failure to do so constitutes a breach of the Terms,
                  which may result in immediate termination of Your account on
                  Our Service.
                </p>
                <p className="tc_para">
                  You are responsible for safeguarding the password that You use
                  to access the Service and for any activities or actions under
                  Your password, whether Your password is with Our Service or a
                  Third-Party Social Media Service.
                </p>
                <p className="tc_para">
                  You agree not to disclose Your password to any third party.
                  You must notify Us immediately upon becoming aware of any
                  breach of security or unauthorized use of Your account.
                </p>
                <p className="tc_para">
                  You may not use as a username the name of another person or
                  entity or that is not lawfully available for use, a name or
                  trademark that is subject to any rights of another person or
                  entity other than You without appropriate authorization, or a
                  name that is otherwise offensive, vulgar or obscene.
                </p>
              </div>
            </div>
            <div className="tc_section">
              <div className="tc_section_title">Intellectual Property</div>
              <div className="tc_block">
                <p className="tc_para">
                  The Service and its original content (excluding Content
                  provided by You or other users), features and functionality
                  are and will remain the exclusive property of the Company and
                  its licensors.
                </p>
                <p className="tc_para">
                  The Service is protected by copyright, trademark, and other
                  laws of both the Country and foreign countries.
                </p>
                <p className="tc_para">
                  Our trademarks and trade dress may not be used in connection
                  with any product or service without the prior written consent
                  of the Company.
                </p>
              </div>
            </div>
            <div className="tc_section">
              <div className="tc_section_title">Links to Other Websites</div>
              <div className="tc_block">
                <p className="tc_para">
                  Our Service may contain links to third-party web sites or
                  services that are not owned or controlled by the Company.
                </p>
                <p className="tc_para">
                  The Company has no control over, and assumes no responsibility
                  for, the content, privacy policies, or practices of any third
                  party web sites or services. You further acknowledge and agree
                  that the Company shall not be responsible or liable, directly
                  or indirectly, for any damage or loss caused or alleged to be
                  caused by or in connection with the use of or reliance on any
                  such content, goods or services available on or through any
                  such web sites or services.
                </p>
                <p className="tc_para">
                  We strongly advise You to read the terms and conditions and
                  privacy policies of any third-party web sites or services that
                  You visit.
                </p>
              </div>
            </div>
            <div className="tc_section">
              <div className="tc_section_title">Termination</div>
              <div className="tc_block">
                <p className="tc_para">
                  We may terminate or suspend Your Account immediately, without
                  prior notice or liability, for any reason whatsoever,
                  including without limitation if You breach these Terms and
                  Conditions.
                </p>
                <p className="tc_para">
                  Upon termination, Your right to use the Service will cease
                  immediately. If You wish to terminate Your Account, You may
                  simply discontinue using the Service.
                </p>
              </div>
            </div>
            <div className="tc_section">
              <div className="tc_section_title">Data Management</div>
              <div className="tc_block">
                <div className="tc_subtitle">Data Retention and Deletion</div>
                <p className="tc_para">
                  Notwithstanding any requirements under applicable agreements
                  to the contrary, We will retain private data only as necessary
                  to carry out the Service and thereafter must delete private
                  data accordingly within 60 days of Service termination.
                </p>
              </div>
              <div className="tc_block">
                <div className="tc_subtitle">Data Usage</div>
                <p className="tc_para">
                  Except when required by law or expressly permitted by You, the
                  Company may only use Your private data, provided to Us or
                  collected through Your vendor portals, for the purposes of
                  providing the Service to You.{" "}
                </p>
              </div>
            </div>
            <div className="tc_section">
              <div className="tc_section_title">Limitation of Liability</div>
              <div className="tc_block">
                <p className="tc_para">
                  Notwithstanding any damages that You might incur, the entire
                  liability of the Company and any of its suppliers under any
                  provision of this Terms and Your exclusive remedy for all of
                  the foregoing shall be limited to the maximum amount paid by
                  You through the Service during the 30 days preceding the date
                  the claim arose or 100 USD if You haven’t purchased anything
                  through the Service.
                </p>
                <p className="tc_para">
                  To the maximum extent permitted by applicable law, in no event
                  shall the Company or its suppliers be liable for any special,
                  incidental, indirect, or consequential damages whatsoever
                  (including, but not limited to, damages for loss of profits,
                  loss of data or other information, for business interruption,
                  for personal injury, loss of privacy arising out of or in any
                  way related to the use of or inability to use the Service,
                  third-party software and/or third-party hardware used with the
                  Service, or otherwise in connection with any provision of this
                  Terms), even if the Company or any supplier has been advised
                  of the possibility of such damages and even if the remedy
                  fails of its essential purpose.
                </p>
                <p className="tc_para">
                  Some states do not allow the exclusion of implied warranties
                  or limitation of liability for incidental or consequential
                  damages, which means that some of the above limitations may
                  not apply. In these states, each party’s liability will be
                  limited to the greatest extent permitted by law.
                </p>
              </div>
            </div>
            <div className="tc_section">
              <div className="tc_section_title">
                “AS IS” and “AS AVAILABLE” Disclaimer
              </div>
              <div className="tc_block">
                <p className="tc_para">
                  The Service is provided to You “AS IS” and “AS AVAILABLE” and
                  with all faults and defects without warranty of any kind. To
                  the maximum extent permitted under applicable law, the
                  Company, on its own behalf and on behalf of its Affiliates and
                  its and their respective licensors and service providers,
                  expressly disclaims all warranties, whether express, implied,
                  statutory or otherwise, with respect to the Service, including
                  all implied warranties of merchantability, fitness for a
                  particular purpose, title and non-infringement, and warranties
                  that may arise out of course of dealing, course of
                  performance, usage or trade practice. Without limitation to
                  the foregoing, the Company provides no warranty or
                  undertaking, and makes no representation of any kind that the
                  Service will meet Your requirements, achieve any intended
                  results, be compatible or work with any other software,
                  applications, systems or services, operate without
                  interruption, meet any performance or reliability standards or
                  be error free or that any errors or defects can or will be
                  corrected.
                </p>
                <p className="tc_para">
                  Without limiting the foregoing, neither the Company nor any of
                  the company’s provider makes any representation or warranty of
                  any kind, express or implied: (i) as to the operation or
                  availability of the Service, or the information, content, and
                  materials or products included thereon; (ii) that the Service
                  will be uninterrupted or error-free; (iii) as to the accuracy,
                  reliability, or currency of any information or content
                  provided through the Service; or (iv) that the Service, its
                  servers, the content, or e-mails sent from or on behalf of the
                  Company are free of viruses, scripts, trojan horses, worms,
                  malware, timebombs or other harmful components.
                </p>
                <p className="tc_para">
                  Some jurisdictions do not allow the exclusion of certain types
                  of warranties or limitations on applicable statutory rights of
                  a consumer, so some or all of the above exclusions and
                  limitations may not apply to You. But in such a case the
                  exclusions and limitations set forth in this section shall be
                  applied to the greatest extent enforceable under applicable
                  law.
                </p>
              </div>
            </div>
            <div className="tc_section">
              <div className="tc_section_title">Governing Law</div>
              <div className="tc_block">
                <p className="tc_para">
                  The laws of the State of Washington, excluding its conflicts
                  of law rules, shall govern these Terms and Your use of the
                  Service. Your use of the Application may also be subject to
                  other local, state, national, or international laws.
                </p>
              </div>
            </div>
            <div className="tc_section">
              <div className="tc_section_title">Disputes Resolution</div>
              <div className="tc_block">
                <p className="tc_para">
                  If You have any concern or dispute about the Service, You
                  agree to first try to resolve the dispute informally by
                  contacting the Company.
                </p>
              </div>
            </div>
            <div className="tc_section">
              <div className="tc_section_title">
                For European Union (EU) Users
              </div>
              <div className="tc_block">
                <p className="tc_para">
                  If You are a European Union consumer, you will benefit from
                  any mandatory provisions of the law of the country in which
                  you are resident in.
                </p>
              </div>
            </div>
            <div className="tc_section">
              <div className="tc_section_title">
                United States Federal Government End Use Provisions
              </div>
              <div className="tc_block">
                <p className="tc_para">
                  If You are a U.S. federal government end user, our Service is
                  a “Commercial Item” as that term is defined at 48 C.F.R.
                  §2.101.
                </p>
              </div>
            </div>
            <div className="tc_section">
              <div className="tc_section_title">
                United States Legal Compliance
              </div>
              <div className="tc_block">
                <p className="tc_para">
                  You represent and warrant that (i) You are not located in a
                  country that is subject to the United States government
                  embargo, or that has been designated by the United States
                  government as a “terrorist supporting” country, and (ii) You
                  are not listed on any United States government list of
                  prohibited or restricted parties.
                </p>
              </div>
            </div>

            <div className="tc_section">
              <div className="tc_section_title">Severability and Waiver</div>
              <div className="tc_block">
                <div className="tc_subtitle">Severability</div>
                <p className="tc_para">
                  If any provision of these Terms is held to be unenforceable or
                  invalid, such provision will be changed and interpreted to
                  accomplish the objectives of such provision to the greatest
                  extent possible under applicable law and the remaining
                  provisions will continue in full force and effect.
                </p>
              </div>
              <div className="tc_block">
                <div className="tc_subtitle">Waiver</div>
                <p className="tc_para">
                  Except as provided herein, the failure to exercise a right or
                  to require performance of an obligation under this Terms shall
                  not impact a party’s ability to exercise such right or require
                  such performance at any time thereafter nor shall be the
                  waiver of a breach constitute a waiver of any subsequent
                  breach.
                </p>
              </div>
            </div>
            <div className="tc_section">
              <div className="tc_section_title">Translation Interpretation</div>
              <div className="tc_block">
                <p className="tc_para">
                  These Terms and Conditions may have been translated if We have
                  made them available to You on our Service.
                </p>
                <p className="tc_para">
                  You agree that the original English text shall prevail in the
                  case of a dispute.
                </p>
              </div>
            </div>
            <div className="tc_section">
              <div className="tc_section_title">
                Changes to These Terms and Conditions
              </div>
              <div className="tc_block">
                <p className="tc_para">
                  We reserve the right, at Our sole discretion, to modify or
                  replace these Terms at any time. If a revision is material We
                  will make reasonable efforts to provide at least 30 days’
                  notice prior to any new terms taking effect. What constitutes
                  a material change will be determined at Our sole discretion.
                </p>
                <p className="tc_para">
                  By continuing to access or use Our Service after those
                  revisions become effective, You agree to be bound by the
                  revised terms. If You do not agree to the new terms, in whole
                  or in part, please stop using the website and the Service.
                </p>
              </div>
            </div>
            <div className="tc_section">
              <div className="tc_section_title">Contact Us</div>
              <div className="tc_block">
                <p className="tc_para">
                  If you have any questions about these Terms and Conditions,
                  You can contact us:
                </p>
                <ul className="tc_points">
                  <li>
                    <span className="tc_bold">By email:</span> 
                    <a href="mailto:CustomerService@visualcogs.com">
                      CustomerService@visualcogs.com
                    </a>
                  </li>
                </ul>
                {/* <p className="tc_para">
                  **These Terms were last revised in July,2021
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PrivacyPolicy;
